import React, { ReactNode, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import AddchartIcon from '@mui/icons-material/Addchart';
import AssessmentIcon from '@mui/icons-material/Assessment';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Position } from '../../services/api';
import useStyles from '../../styles';
import {
  setClickedItem,
  setHighlightedItem,
  setSelectedSensors,
  toggleActivePlotVar,
} from '../../state/actions';
import {
  getHighlightedItem,
  getSelectedSensorIds,
  getClickedItem,
  getActivePlotVars,
  getActiveMarker,
} from '../../state/selectors';
import { VarName } from '../../utils/varNames';
import { persistState, StorageTypes } from '../../utils/persistentState';
import { useAppDispatch, useAppSelector } from '../../state/store';

interface ArrayRowProps {
  id: string;
  varName: VarName;
  position: Position | undefined;
  children: ReactNode;
}

function BaseArrayRow({ id, children, position, varName }: ArrayRowProps): JSX.Element {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const highlightedItem = useAppSelector(getHighlightedItem);
  const selectedSensorIds = useAppSelector(getSelectedSensorIds);
  const clickedItem = useAppSelector(getClickedItem);
  const activePlotVars = useAppSelector(getActivePlotVars);
  const activeMarker = useAppSelector(getActiveMarker);
  const myRef = useRef<HTMLLIElement>(null);
  const matches = useMediaQuery(useTheme().breakpoints.up('md'));

  useEffect(() => {
    if (matches) {
      const { id: highlightedItemId, source } = highlightedItem;
      if (
        myRef.current &&
        varName === activeMarker &&
        source !== 'dashboardPanel' &&
        highlightedItemId === id
      ) {
        myRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [highlightedItem, id, activeMarker, varName, matches]);

  const lockItemHandler = () => {
    if (id === clickedItem.id) {
      dispatch(setClickedItem({ id: '' }));
    } else {
      dispatch(setClickedItem({ id, position, varName }));
    }
  };

  const toggleSelectedSensor = () => {
    const currentIndex = selectedSensorIds.indexOf(id);
    const newSelectedSensorIds = [...selectedSensorIds];

    if (currentIndex === -1) {
      newSelectedSensorIds.push(id);

      // Activate the plot var in sensor data too
      if (activePlotVars.indexOf(varName) === -1) {
        dispatch(toggleActivePlotVar(varName));
      }
    } else {
      newSelectedSensorIds.splice(currentIndex, 1);
    }

    persistState(newSelectedSensorIds, StorageTypes.SelectedPlotSensors);
    dispatch(setSelectedSensors(newSelectedSensorIds));
  };

  const mouseEnter = () => {
    dispatch(setHighlightedItem({ id, varName, source: 'dashboardPanel' }));
  };

  const mouseLeave = () => {
    dispatch(setHighlightedItem({ id: '' }));
  };

  return (
    <ListItem
      ref={myRef}
      className={`${classes.sensorArraySensorListItem} ${
        highlightedItem.id === id && classes.highlightedItem
      }`}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onFocus={mouseEnter}
      onBlur={mouseLeave}
    >
      {children}
      <div style={{ flex: '1 1 auto', width: '1px' }} />
      <Link
        variant="button"
        href={`/sensors/${id}`}
        className={`MuiButton-startIcon MuiButton-iconSizeSmall sensorArraySensorListItemButtons${
          clickedItem?.id === id ? ' sensorArraySensorListItemButtonsActive' : ''
        }`}
        style={{
          display: 'flex',
          alignItems: 'center',
          verticalAlign: 'middle',
        }}
        color="secondary"
      >
        <InfoIcon />
        <span style={{ marginLeft: '0.5rem' }} className={classes.hideInSmallScreen}>
          Info
        </span>
      </Link>
      <Button
        startIcon={clickedItem?.id === id ? <LockIcon /> : <LockOpenOutlinedIcon />}
        size="small"
        className={`sensorArraySensorListItemButtons${
          clickedItem?.id === id ? ' sensorArraySensorListItemButtonsActive' : ''
        }`}
        onClick={lockItemHandler}
        color="secondary"
      >
        <span className={classes.hideInSmallScreen}>
          {clickedItem?.id === id ? 'Locked' : 'Lock graph'}{' '}
        </span>
      </Button>
      <Button
        onClick={toggleSelectedSensor}
        size="small"
        className={`sensorArraySensorListItemButtons${
          selectedSensorIds.indexOf(id) === -1 ? '' : ' sensorArraySensorListItemButtonsActive'
        }`}
        startIcon={selectedSensorIds.indexOf(id) === -1 ? <AddchartIcon /> : <AssessmentIcon />}
        color="secondary"
      >
        <span className={classes.hideInSmallScreen}>
          {selectedSensorIds.indexOf(id) === -1 ? 'Add to Compare' : 'In compare'}
        </span>
      </Button>
    </ListItem>
  );
}

export default BaseArrayRow;
