import React, { useState, useEffect, ChangeEvent } from 'react';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import n2words from 'number-to-words';
import { PropLocationItem, PropLocationItemCreate, LocationType } from '../../services/api/api';
import { createNewLocation } from '../../services/apiService';
import { updateLocationData } from '../../state/actions';
import { locationProperties, locationTypeDetail, KeyAny, AlertMsg } from './LocationProperties';
import useStyles from '../../styles/location';
import { themeProps } from '../../styles/theme';
import { useAppDispatch } from '../../state/store';

interface CreateLocationProps {
  locationDetails: PropLocationItem;
  setAlertMsg: (value: AlertMsg | undefined) => void;
  updateLocationChildren: () => void;
  setShowCreateLocationForm: (value: boolean) => void;
}

interface CreateLocationItem {
  name?: string;
  shortName?: string;
  type?: LocationType;
  location?: string;
  timeZone?: string;
}

interface FloorType {
  id: number;
  name: string;
  shortName: string;
  [key: string]: string | number;
}

function CreateLocation({
  locationDetails,
  setAlertMsg,
  updateLocationChildren,
  setShowCreateLocationForm,
}: CreateLocationProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [locationCreateItem, setLocationCreateItem] = useState<CreateLocationItem>();
  const [newLocationId, setNewLocationId] = useState<string>('');
  const [isAddingFloor, setIsAddingFloor] = useState<boolean>(false);
  const [floors, setFloors] = useState<Array<FloorType>>([]);
  const [floorCount, setFloorCount] = useState<number>(0);
  const hiddenFields = ['metadata', 'id', 'position', 'latest', 'type'];

  useEffect(() => {
    const item = {
      name: '',
      shortName: '',
      location: locationDetails?.id,
      timeZone: locationDetails?.timeZone ?? 'Europe/London',
      type: locationDetails?.id === '#' ? LocationType.Client : LocationType.Building,
    };
    setLocationCreateItem(item);
  }, [locationDetails]);

  const formChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, param: string) => {
    const { value } = e.target;
    let item;
    if (param === 'name') {
      const trimmedShortName = value.replace(/ +/g, '').trim().substring(0, 10);
      const capitalizedShortName =
        trimmedShortName.charAt(0).toUpperCase() + trimmedShortName.slice(1);
      item = { ...locationCreateItem, [param]: value, shortName: capitalizedShortName };
    } else if (param === 'shortName') {
      const fixedLengthShortName = value.replace(/ +/g, '').trim().substring(0, 10);
      item = { ...locationCreateItem, [param]: fixedLengthShortName };
    } else {
      item = { ...locationCreateItem, [param]: value };
    }
    setLocationCreateItem(item);
  };

  useEffect(() => {
    const locationType = locationCreateItem?.type;
    const locationId = locationDetails?.id === '#' ? '' : locationDetails.id;
    if (locationType)
      setNewLocationId(
        `${locationId}#${locationTypeDetail[locationType]?.code ?? 'BLD'}-${
          locationCreateItem?.shortName
        }`
      );
    if (locationCreateItem?.shortName?.length === 10) {
      setAlertMsg({
        success: false,
        msg: 'Shortname 10 Character length limit reached',
        alertType: 'info',
      });
    }
  }, [locationCreateItem, locationDetails.id, setAlertMsg]);

  const createLocation = () => {
    if (locationCreateItem?.name === '' || locationCreateItem?.shortName === '') {
      setAlertMsg({ success: true, msg: 'Please enter required fields', alertType: 'error' });
    } else if (locationCreateItem) {
      createNewLocation(newLocationId, locationCreateItem as PropLocationItemCreate)
        .then((response) => {
          setShowCreateLocationForm(false);
          setAlertMsg({ success: true, msg: 'New Location Created', alertType: 'success' });
          updateLocationChildren();
          dispatch(updateLocationData(response));
        })
        .catch((err) => setAlertMsg({ success: false, msg: err.cause, alertType: 'error' }));
    }
  };

  const createWithFloors = () => {
    if (locationCreateItem?.name === '' || locationCreateItem?.shortName === '') {
      setAlertMsg({ success: true, msg: 'Please enter required fields', alertType: 'error' });
    } else if (!floorCount || (floorCount > 0 && floorCount !== floors.length)) {
      setAlertMsg({
        success: false,
        msg: 'Please check floors to be added',
        alertType: 'error',
      });
    } else if (locationCreateItem) {
      createNewLocation(newLocationId, locationCreateItem as PropLocationItemCreate)
        .then((res) => {
          dispatch(updateLocationData(res));
          if (floors) {
            const floorsCreateItem = floors.map((floor) => {
              const floorId = `${newLocationId}#FL-${floor.shortName}`;
              const createItem = {
                name: floor.name,
                shortName: floor.shortName.toString(),
                location: newLocationId,
                timeZone: locationDetails?.timeZone ?? 'Europe/London',
                type: LocationType.FloorLevel,
              };
              return createNewLocation(floorId, createItem as PropLocationItemCreate);
            });
            Promise.all([...floorsCreateItem])
              .then((results) => {
                updateLocationChildren();
                setShowCreateLocationForm(false);
                results.map((newFloor) => dispatch(updateLocationData(newFloor)));
                setAlertMsg({
                  success: true,
                  msg: 'Location successfully created',
                  alertType: 'success',
                });
              })
              .catch(() => {
                setShowCreateLocationForm(false);
                setAlertMsg({
                  success: false,
                  msg: 'Location successfully created but failed to create floor',
                  alertType: 'error',
                });
              });
          }
        })
        .catch((err) => {
          setAlertMsg({
            success: false,
            msg: err.cause,
            alertType: 'error',
          });
        });
    }
  };

  const addFloor = (value: string) => {
    const count = parseInt(value, 10);
    if (count >= 0) {
      setFloorCount(count);
      const field = Array.from(Array(count).keys());
      const allFloors: Array<FloorType> = [];
      field.map((floorLevel) => {
        if (floorLevel === 0) {
          return allFloors.push({
            id: parseInt(Math.random().toFixed(0), 10) * 100,
            name: 'Ground Floor',
            // eslint-disable-next-line no-octal
            shortName: '00',
          });
        }
        const floorToWord = `${n2words.toOrdinal(floorLevel)} Floor`;
        const formatFloorWord = floorToWord.charAt(0).toUpperCase() + floorToWord.slice(1);
        return allFloors.push({
          id: parseInt(Math.random().toFixed(0), 10) * 100 + floorLevel,
          name: formatFloorWord,
          shortName: floorLevel.toString().padStart(2, '0'),
        });
      });
      setFloors(allFloors);
    }
  };

  const changeFloorField = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    id: number,
    index: number,
    type: string
  ) => {
    const { value } = e.target;
    const currentFloors = [...floors];
    const field = currentFloors.find((floor) => floor.id === id);
    if (field) {
      field[type] = value;
      currentFloors[index] = field;
      if (type === 'shortName') {
        let formattedFloorText;
        const floorValue = parseInt(value, 10);
        if (floorValue === 0) {
          formattedFloorText = 'Ground Floor';
        } else {
          const floorToWord = `${n2words.toOrdinal(parseInt(value, 10))} Floor`;
          formattedFloorText = floorToWord.charAt(0).toUpperCase() + floorToWord.slice(1);
        }
        field.name = formattedFloorText;
        currentFloors[index] = field;
      }
      setFloors(currentFloors);
    }
  };

  const showTextFields = () => (
    <>
      {floors.map((floor, i) => (
        <div key={`floorLevel${floor.id}`} style={{ display: 'flex', margin: '0 0 10px 20px' }}>
          <TextField
            label="Floor Level"
            type="number"
            placeholder="Floor count"
            value={floor.shortName}
            onChange={(e) => changeFloorField(e, floor.id, i, 'shortName')}
            variant="standard"
          />
          <TextField
            label="Floor name"
            type="string"
            placeholder="Floor name"
            value={floor.name}
            onChange={(e) => changeFloorField(e, floor.id, i, 'name')}
            style={{ marginLeft: '10px' }}
            variant="standard"
          />
          <br />
        </div>
      ))}
    </>
  );

  return (
    <Grid
      container
      justifyContent="space-evenly"
      style={{ paddingLeft: '10px', paddingRight: '10px' }}
    >
      <Grid item md={8} sm={12} xs={12}>
        <Typography variant="h6" style={{ marginTop: '20px' }}>
          Create New Location
        </Typography>
      </Grid>
      <Grid item md={8} sm={12} xs={12}>
        {locationCreateItem &&
          Object.entries(locationProperties)
            .filter(([locationProps]) => !hiddenFields.includes(locationProps))
            .map((locationProps) => (
              <Box key={locationProps[0]} className={classes.createLocationTextField}>
                <TextField
                  type="text"
                  required={!locationProps[1].autofilled}
                  label={locationProps[1].label}
                  disabled={!locationProps[1].editable}
                  onChange={(e) => formChange(e, locationProps[0])}
                  value={(locationCreateItem as KeyAny)[locationProps[0]] ?? ''}
                  variant="standard"
                />
                {locationProps[1].label === 'Time Zone' && (
                  <span style={{ fontSize: '0.75rem', color: theme.palette.text.primary }}>
                    Timezone must comply to one from the{' '}
                    <a
                      href="https://en.wikipedia.org/wiki/List_of_tz_database_time_zones"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: theme.palette.text.primary }}
                    >
                      TZ Timezone List
                    </a>
                  </span>
                )}
              </Box>
            ))}
        <div>
          <InputLabel id="location-type">Type</InputLabel>
          <Select
            label="type"
            labelId="location-type"
            value={locationCreateItem?.type ?? LocationType.Building}
            className={classes.createLocationTextField}
            style={{ marginBottom: '10px', width: '100%' }}
            onChange={(e) =>
              formChange(e as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, 'type')
            }
            variant="standard"
          >
            {Object.entries(LocationType).map((locationType) => (
              <MenuItem value={locationType[1]} key={locationType[0]}>
                {locationType[0]}
              </MenuItem>
            ))}
          </Select>
        </div>
        <TextField
          type="text"
          label={`${locationCreateItem?.type} Id`}
          style={{ width: '100%', paddingTop: '10px', textTransform: 'capitalize' }}
          value={newLocationId}
          onChange={(e) => setNewLocationId(e.target.value)}
          variant="standard"
        />
        {locationCreateItem?.type === 'building' && (
          <div style={{ marginTop: '10px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isAddingFloor}
                  onChange={() => setIsAddingFloor(!isAddingFloor)}
                />
              }
              label="Do you want to add floors to this building?"
              style={{ marginTop: '15px' }}
            />
            {isAddingFloor && (
              <TextField
                type="number"
                value={floorCount}
                label="Number of Floors"
                style={{ marginLeft: '20px' }}
                onChange={(e) => addFloor(e.target.value)}
                inputProps={{ 'data-testid': 'content-input' }}
                variant="standard"
              />
            )}
            {floorCount > 0 && isAddingFloor && showTextFields()}
          </div>
        )}
      </Grid>
      <Grid item md={8} sm={12} xs={12}>
        <Button
          className={classes.listSensorBtn}
          variant={themeProps.btnVariant.default}
          startIcon={<AddCircleIcon />}
          onClick={isAddingFloor ? createWithFloors : createLocation}
        >
          Create New Location
        </Button>
      </Grid>
    </Grid>
  );
}

export default CreateLocation;
