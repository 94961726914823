export enum ContactType {
  email = 'email',
  phone = 'phone',
  web = 'web',
}

export interface ContactMethod {
  type: ContactType;
  value: string;
  displayValue?: string; // Optional formatted display version, eg: 123 456 789
  additionalInfo?: string; // For extensions, departments, etc.
  isPreferred?: boolean; // Mark preferred contact methods
  icon?: string; // Icon identifier for UI
}

export interface ContactDetails {
  id: string; // Unique identifier for the contact
  name: string; // Name of the facilities team/department
  description: string; // General description or welcome message
  preferredText: string; // Instruction text to show users
  contactMethods?: ContactMethod[]; // Optional for now but in future we need a way for contact methods from facilities team
}

// Example implementation
export const TFLContactDetails: ContactDetails = {
  id: 'tfl-facilities',
  name: 'TfL Facilities Help Desk',
  description: 'Transport for London Facilities Management',
  preferredText: `To report a concern please contact TfL's facilities help desk directly via:`,
  contactMethods: [
    {
      type: ContactType.email,
      value: 'Facilities.HelpDesk@tube.tfl.gov.uk',
      displayValue: 'Facilities.HelpDesk@tube.tfl.gov.uk',
      isPreferred: true,
      icon: 'email',
    },
    {
      type: ContactType.phone,
      value: '02071261999',
      displayValue: '020 7126 1999',
      additionalInfo: 'select option 1',
      icon: 'phone',
    },
  ],
};

export const GenericContactDetails: ContactDetails = {
  id: 'building-facilities',
  name: 'Building Facilities Help Desk',
  description: 'Building Facilities Management.',
  preferredText: 'To report a concern please contact your facilities team.',
};

// Create a helper function for generating email templates
export function createEmailTemplate(
  contactName: string,
  email: string,
  subject: string,
  locationInfo: string,
  category: string
): string {
  return `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(`Dear ${contactName},

[please describe your issue]

Category: ${category}
My Location: ${locationInfo}

Thank you,
[Your Name]`)}`;
}
