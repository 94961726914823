import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

import { DatePickerStateProvider } from '@rehookify/datepicker';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import CalendarSelection from './CalendarSelection';
import { themeProps } from '../../styles/theme';
import { getSelectedEndDate, getSelectedStartDate } from '../../state/selectors';
import ShortcutMenu from './ShortcutMenu';
import { useAppSelector } from '../../state/store';

dayjs.extend(isToday);

export enum CalendarPickerType {
  days = 'days',
  month = 'month',
}

function DateRangeContent() {
  const [pickerType, setPickerType] = useState(CalendarPickerType.days);
  const [jumpOptionsAnchor, setJumpOptionsAnchor] = useState<null | HTMLElement>(null);

  const startDate = useAppSelector(getSelectedStartDate);
  const endDate = useAppSelector(getSelectedEndDate);
  const openJumpOptions = Boolean(jumpOptionsAnchor);

  const dateLabel = useMemo(() => {
    const start = dayjs(startDate).format('D MMM YYYY');
    const end = dayjs(endDate).format('D MMM YYYY');
    let label = `${start} - ${end}`;
    const isSelectedToday = dayjs(startDate).isToday() && dayjs(endDate).isToday();
    if (isSelectedToday) label = 'Today';
    else if (start === end) label = start;
    return label;
  }, [endDate, startDate]);

  return (
    <Box sx={{ alignSelf: 'center' }}>
      <Menu
        id="settings-menu"
        anchorEl={jumpOptionsAnchor}
        open={openJumpOptions}
        onClose={() => setJumpOptionsAnchor(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box sx={{ display: 'flex' }}>
          <ShortcutMenu resetPickerType={() => setPickerType(CalendarPickerType.days)} />
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <CalendarSelection pickerType={pickerType} setPickerType={setPickerType} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'end' } }}>
          <Button
            variant={themeProps.btnVariant.default}
            sx={{ margin: '0 10px 0 0' }}
            onClick={() => setJumpOptionsAnchor(null)}
          >
            Close
          </Button>
        </Box>
      </Menu>
      <Button
        variant={themeProps.btnVariant.text}
        onClick={(event) => setJumpOptionsAnchor(event.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={<CalendarMonthIcon />}
      >
        {dateLabel}
      </Button>
    </Box>
  );
}

function DateRangeContainer() {
  const [selectedDates, onDatesChange] = useState<Date[]>([]);
  const startDate = useAppSelector(getSelectedStartDate);
  const endDate = useAppSelector(getSelectedEndDate);

  useEffect(() => {
    onDatesChange([startDate, endDate]);
  }, [endDate, startDate]);

  return (
    <DatePickerStateProvider
      config={{
        selectedDates,
        onDatesChange,
        dates: {
          mode: 'range',
          maxDate: new Date(),
          selectSameDate: true,
        },
        calendar: {
          offsets: [-1, -1],
        },
      }}
    >
      <DateRangeContent />
    </DatePickerStateProvider>
  );
}

export default DateRangeContainer;
