import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Variant } from '@mui/material/styles/createTypography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

import { getDataBandParams, varNameBandParams } from '../../utils/dataBandParams';
import {
  getActiveMarker,
  getClickedItem,
  getHighlightedItem,
  getLocSensorStatusData,
  getMotionThreshold,
  getSensorsById,
} from '../../state/selectors';
import { getDataValueString } from '../HelperComponents/DataValueString';
import { MotionSlider } from '../SensorArrayWidgets/MotionEventsSensor';
import { VarName, varNameDetails } from '../../utils/varNames';
import ColouredLinePlotWrapper from '../HelperComponents/ColouredLinePlotWrapper';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/dashboard';
import { themeProps } from '../../styles/theme';
import { bandRangeStr, bandStyles } from '../../components/varNameDescriptions';
import useOutsideReading from './useOutsideReading';
import { checkOnlineStatus } from '../../utils/sensors';
import { useAppSelector } from '../../state/store';

enum PlotRange {
  today = 'Today',
  last3Days = 'Last 3 Days',
}

function SensorPlotContainer(): JSX.Element {
  const theme = useTheme();
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const isDesktopView = useMediaQuery(theme.breakpoints.up('sm'));

  const rangeValue = useAppSelector(getMotionThreshold);
  const sensorsById = useAppSelector(getSensorsById);
  const activeMarker = useAppSelector(getActiveMarker);
  const locSensorStatusData = useAppSelector(getLocSensorStatusData);
  const highlightedItem = useAppSelector(getHighlightedItem);
  const clickedItem = useAppSelector(getClickedItem);
  const bandParams = varNameBandParams[activeMarker] ?? [];

  const [start, setStart] = useState<number>(dayjs().subtract(2, 'days').startOf('D').unix()); // set default start time to last 3 days
  const [end, setEnd] = useState<number>(dayjs().unix());
  const [showBandDialog, setShowBandDialog] = useState<boolean>(false);
  const [plotRange, setPlotRange] = useState<PlotRange>(
    isDesktopView ? PlotRange.last3Days : PlotRange.today
  );
  const outsideReading = useOutsideReading(); // outside reading for temp/hum and avg reading for other sources

  const selectedSensorId = highlightedItem?.id ? highlightedItem.id : clickedItem.id;

  const showOutsideWeather =
    activeMarker === VarName.TemperatureC || activeMarker === VarName.RelativeHumidity;

  const isOnline = checkOnlineStatus(locSensorStatusData, selectedSensorId, activeMarker);

  useEffect(() => {
    if (plotRange === PlotRange.today) {
      setStart(dayjs().startOf('D').unix());
      setEnd(dayjs().unix());
    } else {
      setStart(dayjs().subtract(2, 'days').startOf('D').unix());
      setEnd(dayjs().unix());
    }
  }, [plotRange]);

  const selectedSensorData = sensorsById
    .get(selectedSensorId)
    ?.data?.find((item) => item.varName === activeMarker);

  // null if there is no data else show band or default band where labels are 'Unknown'
  const selectedSensorBand =
    selectedSensorData?.value !== undefined
      ? getDataBandParams(activeMarker, selectedSensorData?.value)
      : null;

  const showReadings = (value: number | undefined, variant?: Variant, metricSize?: Variant) => {
    const color =
      value !== undefined
        ? getDataBandParams(activeMarker, value)?.color
        : theme.palette.text.primary;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {value !== undefined ? (
          <>
            <Typography
              variant={variant ?? 'h3'}
              sx={{
                color,
              }}
            >
              {getDataValueString(
                value,
                activeMarker,
                selectedSensorData?.time,
                rangeValue,
                false,
                false
              )}
            </Typography>
            <Typography variant={metricSize ?? 'h4'} sx={{ color }}>
              {varNameDetails[activeMarker].metric}
            </Typography>
          </>
        ) : (
          '-'
        )}
      </Box>
    );
  };

  const showToggleBtnGroup = () => (
    <ToggleButtonGroup
      size="small"
      color="primary"
      value={plotRange}
      exclusive
      onChange={(e, val) => setPlotRange(val)}
      aria-label="plot range"
      sx={{
        width: { xs: '100%', sm: 'auto' },
        justifyContent: 'center',
      }}
    >
      <ToggleButton value={PlotRange.today} sx={{ textTransform: 'capitalize' }}>
        {PlotRange.today}
      </ToggleButton>
      <ToggleButton value={PlotRange.last3Days} sx={{ textTransform: 'capitalize' }}>
        {PlotRange.last3Days}
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const showSensorPlot = () => (
    <>
      {activeMarker === VarName.MotionEvent && (
        <Box sx={{ padding: '0 20px' }}>
          <MotionSlider />
        </Box>
      )}
      {selectedSensorId && (
        <div className={globalClasses.sensorGraph} style={{ border: 'none', boxShadow: 'none' }}>
          <ColouredLinePlotWrapper
            varName={activeMarker}
            sensorId={selectedSensorId}
            transparentBg
            start={start * 1000}
            end={end * 1000}
          />
        </div>
      )}
    </>
  );

  const showBandLabelPill = () => {
    if (!selectedSensorBand || selectedSensorBand.label === 'Unknown') return null;
    return (
      <Typography
        variant="body2"
        className={classes.pillBandLabel}
        style={{
          borderColor: 'transparent',
          textAlign: 'center',
          background: `${selectedSensorBand?.color}84`,
        }}
      >
        {selectedSensorBand?.label ?? '-'}
      </Typography>
    );
  };

  const showMobileView = () => (
    <>
      <Box>
        <Grid container sx={{ textAlign: 'center', marginTop: '8px' }}>
          <Grid item xs={6} sx={{ padding: '8px' }}>
            <span>{sensorsById.get(selectedSensorId)?.name ?? ' Sensor reading'}</span>
            <Box
              sx={{
                background: theme.palette.primary.light,
                marginTop: '8px',
                alignItems: 'center',
              }}
              className={globalClasses.appMenuBtn}
            >
              {selectedSensorData?.value !== undefined && isOnline
                ? showReadings(selectedSensorData?.value)
                : '-'}
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ padding: '8px' }}>
            <span>{showOutsideWeather ? 'Outside' : 'Location Average'}</span>
            <Box
              sx={{
                background: theme.palette.primary.light,
                marginTop: '8px',
              }}
              className={globalClasses.appMenuBtn}
            >
              {showReadings(outsideReading)}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {selectedSensorBand && selectedSensorBand.label !== 'Unknown' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            background: theme.palette.primary.light,
            margin: '8px',
            borderRadius: '8px',
            padding: '8px',
          }}
        >
          <Box
            sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
            onClick={() => setShowBandDialog(true)}
          >
            {showBandLabelPill()}
            <Box sx={{ marginLeft: '8px' }}>
              <Typography variant="body1">{selectedSensorBand?.description ?? '-'}</Typography>
            </Box>
            <HelpOutlineIcon sx={{ marginLeft: '10px' }} fontSize="small" />
          </Box>
        </Box>
      )}

      <Box
        sx={{
          background: theme.palette.primary.light,
          margin: '16px 8px',
          borderRadius: '8px',
          padding: '16px 8px 8px 8px',
        }}
      >
        {showToggleBtnGroup()}
        {showSensorPlot()}
      </Box>
    </>
  );

  const showDesktopView = () => (
    <Box
      style={{
        margin: '8px',
        background: theme.palette.primary.light,
        borderRadius: '8px',
        paddingBottom: '2px',
        minHeight: activeMarker === VarName.MotionEvent ? '310px' : '260px', // 50px spacing for motion slider
      }}
    >
      <Box sx={{ minHeight: '40px' }}>
        {/* band will be null only if there is no data  */}
        <Box
          sx={{
            display: 'flex',
            padding: '8px 8px 0 8px',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'start',
            }}
            onClick={() => setShowBandDialog(true)}
          >
            <Tooltip
              title={
                selectedSensorData?.time
                  ? `at ${dayjs(selectedSensorData.time * 1000).format('LLL')}`
                  : ''
              }
            >
              <Box>
                <span>{sensorsById.get(selectedSensorId)?.name ?? ' Sensor reading'}</span>
                {selectedSensorData?.value !== undefined && isOnline && (
                  <Box sx={{ display: 'flex' }}>
                    {showReadings(selectedSensorData?.value, 'h4', 'h6')}{' '}
                    {selectedSensorBand && selectedSensorBand.label !== 'Unknown' && (
                      <>
                        <Box sx={{ marginLeft: '8px' }}>{showBandLabelPill()}</Box>
                        <HelpOutlineIcon
                          sx={{ marginLeft: '8px', marginTop: '3px' }}
                          fontSize="small"
                        />
                      </>
                    )}
                  </Box>
                )}
              </Box>
            </Tooltip>
          </Box>
          <Box>{showToggleBtnGroup()}</Box>
          <Tooltip
            title={
              showOutsideWeather
                ? 'Current Weather condition outside of this location'
                : 'Current average of all sensors from location'
            }
          >
            <Box sx={{ textAlign: 'end' }}>
              <span>{showOutsideWeather ? 'Outside' : 'Location Average'}</span>
              {showReadings(outsideReading, 'h4', 'h6')}
            </Box>
          </Tooltip>
        </Box>
      </Box>
      {showSensorPlot()}
    </Box>
  );

  return (
    <Box>
      {bandParams.length > 1 && (
        <Dialog
          open={showBandDialog}
          style={{ textAlign: 'center' }}
          onClose={() => setShowBandDialog(false)}
        >
          <DialogContent>
            <ul style={bandStyles.listStyle}>
              {bandParams.map((band) => (
                <li style={bandStyles.listItemStyle} key={band.label}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FiberManualRecordIcon sx={{ fontSize: '16px', color: band.color }} />
                    <Typography variant="body1" sx={{ textAlign: 'left', marginLeft: '8px' }}>
                      {band.label} ({bandRangeStr(bandParams).get(band.label)}{' '}
                      {varNameDetails[activeMarker].metric}){' '}
                      {band.description && `- ${band.description}`}
                    </Typography>
                  </Box>
                </li>
              ))}
            </ul>
            <Button
              variant={themeProps.btnVariant.default}
              sx={{
                margin: '10px',
                width: '50%',
                background: themeProps.colors.warningRed,
                border: 'none',
              }}
              onClick={() => setShowBandDialog(false)}
            >
              Close
            </Button>
          </DialogContent>
        </Dialog>
      )}
      {isDesktopView ? showDesktopView() : showMobileView()}
    </Box>
  );
}

export default SensorPlotContainer;
