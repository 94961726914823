import React, { useEffect, useState, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import useStyles from '../../styles/location';
import { fetchSensorLatest, updateSensorProperties } from '../../services/apiService';
import { SensorLatest, VisibleSubSensor } from '../../services/api';
import SensorDetails from '../SensorConfig/SensorDetails/SensorDetails';
import Loading from '../../components/Loading';
import { filterHighStrengthGateway, GatewayVisibleSubsensors } from '../SensorConfig/helpers';
import { AlertMsg } from '../LocationConfig/LocationProperties';
import CreateSensor from '../SensorConfig/CreateSensor';
import { setSensorsById } from '../../state/actions';
import { useAppDispatch } from '../../state/store';

interface ConfigureSensorDialogProps {
  showDialog: boolean;
  sensorDetails: VisibleSubSensor;
  locationId: string;
  cancelHandler: () => void;
  refreshSensors: () => void;
  allGatewayVisibleSubSensors: GatewayVisibleSubsensors[];
}

function ConfigureSensorDialog({
  showDialog,
  sensorDetails,
  locationId,
  cancelHandler,
  refreshSensors,
  allGatewayVisibleSubSensors,
}: ConfigureSensorDialogProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [sensorExists, setSensorExists] = useState<boolean>();
  const [sensor, setSensor] = useState<SensorLatest>();
  const [sensorFetching, setSensorFetching] = useState<boolean>(true);
  const [sensorGatewayDetails, setSensorGatewayDetails] = useState<SensorLatest>();
  const [alertMsg, setAlertMsg] = useState<AlertMsg>();

  const getSensorDetails = useCallback(() => {
    fetchSensorLatest(sensorDetails.suggested_id)
      .then((details) => {
        setSensorFetching(false);
        setSensor(details);
        dispatch(setSensorsById([details]));
        setSensorExists(true);
      })
      .catch(() => {
        setSensorFetching(false);
        setSensorExists(false);
      });
  }, [dispatch, sensorDetails.suggested_id]);

  useEffect(() => {
    getSensorDetails();
    setSensorExists(false);
    setSensorFetching(true);
    setAlertMsg({ success: true, msg: '', alertType: 'success' });
  }, [getSensorDetails, locationId, sensorDetails.suggested_id, showDialog]);

  useEffect(() => {
    if (allGatewayVisibleSubSensors) {
      filterHighStrengthGateway(allGatewayVisibleSubSensors, sensorDetails.suggested_id).then(
        (res) => {
          if (res) {
            setSensorGatewayDetails(res);
            if (res.id === '') {
              setAlertMsg({
                success: true,
                msg: 'High Strength Gateway Not Found',
                alertType: 'error',
              });
            }
          }
        }
      );
    }
  }, [allGatewayVisibleSubSensors, sensorDetails.suggested_id, showDialog]);

  const enableHandler = (status: boolean) => {
    const gatewayStatus = {
      gateway: status ? sensorGatewayDetails?.id : '',
    };

    updateSensorProperties(sensorDetails.suggested_id, gatewayStatus)
      .then(() => {
        setAlertMsg({
          success: true,
          msg: 'Update successful. Sensor status change in progress',
          alertType: 'success',
        });
        getSensorDetails();
      })
      .catch((error) => {
        setAlertMsg({
          success: false,
          msg: error.cause,
          alertType: 'error',
        });
      });
  };

  return (
    <Dialog
      open={showDialog}
      onClose={cancelHandler}
      style={{ backgroundColor: 'transparent' }}
      BackdropProps={{ style: { backgroundColor: '#00000038' } }}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          margin: '0',
          width: 'calc(100% - 20px)',
        },
      }}
    >
      <DialogContent className={classes.configureDialogContent}>
        {sensorFetching && <Loading />}
        {alertMsg?.msg && (
          <Alert
            severity={alertMsg.alertType ?? 'info'}
            onClose={() => setAlertMsg({ success: true, msg: '', alertType: 'success' })}
            className={classes.alertMsg}
          >
            {alertMsg?.msg}
          </Alert>
        )}
        {sensorExists ? (
          <>
            <IconButton
              onClick={cancelHandler}
              style={{ height: '40px', width: '40px', position: 'absolute', top: 0, right: '10px' }}
              data-testid="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
            <SensorDetails sensorId={sensorDetails.suggested_id} sensorDetails={sensor} />
          </>
        ) : (
          sensorGatewayDetails && (
            <CreateSensor
              sensorGatewayDetails={sensorGatewayDetails as SensorLatest}
              subSensorData={sensorDetails}
              creatingSensor={cancelHandler}
              refreshSubSensor={refreshSensors}
              sensorNewId={sensorDetails.suggested_id}
              enableSensor={enableHandler}
            />
          )
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ConfigureSensorDialog;
