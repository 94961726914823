import React, { useEffect, useState, ChangeEvent } from 'react';
import Card from '@mui/material/Card';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import useStyles from '../../styles/index';
import { getCurrentLocation } from '../../state/selectors';
import { SensorPositionData } from '../Map/types';
import { themeProps } from '../../styles/theme';
import { useAppSelector } from '../../state/store';

interface ProvisionInitialDataProps {
  sensorName: string;
  sensorShortName: string;
  nameChangeHandler: (e: ChangeEvent<HTMLInputElement>, param: string) => void;
  setInitialDataCollected: (value: boolean) => void;
  position: SensorPositionData;
  onPositionChange: (newValue: [number, number]) => void;
}

function ProvisionInitialData({
  sensorName,
  sensorShortName,
  nameChangeHandler,
  setInitialDataCollected,
  position,
  onPositionChange,
}: ProvisionInitialDataProps): JSX.Element {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentLocation = useAppSelector(getCurrentLocation);
  const [positionValid, setPositionValid] = useState(true);
  const [currentPosition, setCurrentPosition] = useState('');
  const getSteps = () => ['Location', 'Name', 'Position'];
  const steps = getSteps();

  const nextStepHandler = () => {
    if (activeStep === steps.length - 1) {
      setInitialDataCollected(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  useEffect(() => {
    const { lat, lng } = position;
    if (lat !== undefined && lng !== undefined) {
      setCurrentPosition(`${lat},${lng}`);
      setPositionValid(true);
    }
  }, [position]);

  const onPositionChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ?? '';
    setCurrentPosition(newValue);
    const values = newValue.split(',');
    let valid = false;
    if (values?.length === 2) {
      const lat = Number.parseFloat(values[0]);
      const lng = Number.parseFloat(values[1]);
      if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
        valid = true;
        if (onPositionChange !== undefined) {
          onPositionChange([lat, lng]);
        }
      }
    }
    setPositionValid(valid);
  };

  return (
    <Card variant="outlined" className={classes.provisioningForm} style={{ overflow: 'auto' }}>
      <Typography variant="h5">Enter sensor details</Typography>
      <Stepper activeStep={activeStep} className={classes.provisioningFormStepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.initialProvisionStepper}>
        {activeStep === 0 && (
          <div className={classes.provisioningFormRow}>
            <TextField
              disabled
              required
              style={{ width: 'calc(100% - 1rem' }}
              id="location"
              helperText="Select location from dropdown above"
              value={currentLocation}
              variant="standard"
            />
          </div>
        )}
        {activeStep === 1 && (
          <div className={classes.provisioningFormRow}>
            <TextField
              required
              id="name"
              label="Name"
              value={sensorName}
              onChange={(e) => nameChangeHandler(e as React.ChangeEvent<HTMLInputElement>, 'name')}
              helperText="Hint: A good choice for the name would be the name of the room or area the sensor will be placed in "
              style={{ width: 'calc(100% - 1rem' }}
              variant="standard"
            />
            <TextField
              required
              id="shortName"
              label="Short Name"
              value={sensorShortName}
              onChange={(e) =>
                nameChangeHandler(e as React.ChangeEvent<HTMLInputElement>, 'shortName')
              }
              style={{ width: 'calc(100% - 1rem', marginTop: '10px' }}
              variant="standard"
            />
          </div>
        )}
        {activeStep === 2 && (
          <div className={classes.provisioningFormRow}>
            <InputLabel shrink id="position">
              Click on map to select sensor position
            </InputLabel>
            <TextField
              id="position-coordinates"
              placeholder="Postion"
              error={!positionValid}
              helperText={!positionValid ? 'Incorrect position entry' : undefined}
              value={currentPosition}
              onChange={onPositionChangeHandler}
              style={{ width: 'calc(100% - 1rem)' }}
              variant="standard"
            />
          </div>
        )}
        <Button variant={themeProps.btnVariant.default} onClick={nextStepHandler} color="primary">
          {activeStep === steps.length - 1 ? 'Preview' : 'Next'}
        </Button>
      </div>
    </Card>
  );
}

export default ProvisionInitialData;
