import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import useStyles from '../../styles/dashboard';
import {
  getActiveMarker,
  getClickedItem,
  getHighlightedItem,
  getLocSensorStatusData,
} from '../../state/selectors';
import SensorIcon from '../../styles/icons/SensorIcon';
import { setClickedItem, setSelectedBand } from '../../state/actions';

import { varNameDetails } from '../../utils/varNames';
import SelectedVarsIconBtn from '../../components/SelectedVarsIconBtn';
import SensorPlotContainer from './SensorPlotContainer';
import VarSensorsList from './VarSensorsList';
import useDataFiltering from '../SensorArrayWidgets/useDataFiltering';
import Feedback from '../../Views/App/Feedback/Feedback';
import { themeProps } from '../../styles/theme';
import { useAppDispatch, useAppSelector } from '../../state/store';

interface SensorHistoryProps {
  goToDashboard: () => void;
  isAppDashboard: boolean;
}

function SensorHistory({ goToDashboard, isAppDashboard }: SensorHistoryProps): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const floatingElementRef = useRef(null);
  const activeMarker = useAppSelector(getActiveMarker);
  const locSensorStatusData = useAppSelector(getLocSensorStatusData);
  const highlightedItem = useAppSelector(getHighlightedItem);
  const clickedItem = useAppSelector(getClickedItem);
  const isDesktopView = useMediaQuery(theme.breakpoints.up('sm'));
  const ActiveMarkerIcon = varNameDetails[activeMarker]?.icon ?? SensorIcon;
  const [showFeedbackPage, setShowFeedbackPage] = useState(false);

  const handleBackBtn = () => {
    goToDashboard();
    dispatch(setSelectedBand(null));
    if (isDesktopView) dispatch(setClickedItem({ id: '' }));
  };

  const activeItem = highlightedItem.id ? highlightedItem : clickedItem;

  const sortedOnlineVarValues = useDataFiltering(
    locSensorStatusData?.onlineData.get(activeMarker) ?? []
  );

  // set default clicked item if active item doesn't have active marker sensor
  useEffect(() => {
    if (!activeItem.id || activeItem.varName !== activeMarker) {
      // update when activeMarker values are received
      if (sortedOnlineVarValues.length > 0 && sortedOnlineVarValues[0].varName === activeMarker) {
        dispatch(setClickedItem({ id: sortedOnlineVarValues[0].id, varName: activeMarker }));
      }
    }
  }, [activeItem, activeMarker, dispatch, sortedOnlineVarValues]);

  return (
    <Box>
      {/* selected sensor plot floating container */}
      <Box
        sx={{ width: { xl: '800px', sm: '600px', xs: '100%' } }}
        className={`${classes.sensorHistoryContainer}`}
        ref={floatingElementRef}
      >
        <Box
          className={classes.floatingBox}
          sx={{
            height: isDesktopView ? 'auto' : '100vh !important',
            borderRadius: isDesktopView ? '8px' : '0 !important',
          }}
        >
          {!showFeedbackPage && (
            <>
              <Box
                sx={{
                  display: { sm: 'flex', xs: 'block' },
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '8px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={handleBackBtn}
                    style={{ background: theme.palette.primary.light, height: 'fit-content' }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Box sx={{ marginLeft: '5px' }}>
                    <ActiveMarkerIcon />
                  </Box>
                  <Typography variant="h5" style={{ marginLeft: '10px' }}>
                    {varNameDetails[activeMarker].label}
                  </Typography>
                </div>
                {isDesktopView && <SelectedVarsIconBtn />}
              </Box>
              <Box sx={{ paddingBottom: '2px', marginTop: '-16px' }}>
                <SensorPlotContainer />
              </Box>
              {isAppDashboard && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant={themeProps.btnVariant.default}
                    sx={{
                      borderRadius: '20px',
                      background: theme.palette.primary.light,
                      border: 'none',
                    }}
                    onClick={() => setShowFeedbackPage(true)}
                  >
                    How can we help you?
                  </Button>
                </Box>
              )}
            </>
          )}
          {showFeedbackPage && (
            <Feedback sensorId={clickedItem.id} reset={() => setShowFeedbackPage(false)} />
          )}
        </Box>

        {/* Display Sensors list floating container only on desktop view */}
        {isDesktopView && <VarSensorsList floatingElementRef={floatingElementRef} />}
      </Box>
    </Box>
  );
}

export default SensorHistory;
