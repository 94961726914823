import React, { useState, useEffect, useMemo } from 'react';
import {
  getActivePlotVars,
  getGhostParams,
  getSelectedEndDate,
  getSelectedHours,
  getSelectedSensorIds,
  getSelectedStartDate,
  getSensorsById,
  getSensorsByVarName,
} from '../../state/selectors';
import DataStore from '../../services/dataStore';
import { getHourSet, processRawData } from '../CalendarView/helpers';
import { getUnselectedTimeRange, SensorMetricPlotItem } from '../Plots/plotCommon';
import chartColors from '../../utils/chartColors';
import StackedMetricPlot from '../Plots/StackedMetricPlot';
import { MetricOptions } from '../../components/DateRangePicker';
import CompareReport from './CompareReport';
import LoaderWithBackdrop from '../HelperComponents/LoaderWithBackdrop';
import { useAppSelector } from '../../state/store';

interface MetricDataPlotWrapperProps {
  metricSelection: MetricOptions;
}

function MetricDataPlotWrapper({ metricSelection }: MetricDataPlotWrapperProps): JSX.Element {
  const sensorsByVarName = useAppSelector(getSensorsByVarName);
  const selectedSensorIds = useAppSelector(getSelectedSensorIds);
  const sensorsById = useAppSelector(getSensorsById);
  const selectedVarNames = useAppSelector(getActivePlotVars);
  const startDate = useAppSelector(getSelectedStartDate);
  const endDate = useAppSelector(getSelectedEndDate);
  const selectedHours = useAppSelector(getSelectedHours);
  const { selectHours, startHour, endHour } = selectedHours;
  const [fetchingSensorData, setFetchingSensorData] = useState<boolean>(false);
  const [sensorData, setSensorData] = useState<SensorMetricPlotItem[]>([]);
  const dataStore = DataStore.getInstance();

  const ghostParams = useAppSelector(getGhostParams);
  const { addGhosts, weekCount } = ghostParams;

  useEffect(() => {
    setFetchingSensorData(true);
    const newItems: Promise<SensorMetricPlotItem>[] = [];
    selectedVarNames.forEach((varName) => {
      const sensorVarnameIds = sensorsByVarName.get(varName) ?? [];
      const filteredIds = selectedSensorIds.filter((item) => sensorVarnameIds.includes(item));

      filteredIds.forEach((sensorId) => {
        const promisedItem = dataStore
          .getSensorDailyMetricHistory(
            sensorId,
            varName,
            new Date(startDate).getTime(),
            new Date(endDate).getTime()
          )
          .then((history) => {
            const processedData = processRawData(
              history,
              getHourSet(selectHours, startHour, endHour),
              metricSelection === MetricOptions.Hourly
            );
            const dataItem: SensorMetricPlotItem = {
              sensorId,
              varName,
              sensorName: sensorsById.get(sensorId)?.name ?? sensorId,
              metrics: processedData,
              color: chartColors[selectedSensorIds.indexOf(sensorId) % chartColors.length],
            };
            return dataItem;
          })
          .catch(() => {
            // If we can't find daily history use empty history
            const dataItem: SensorMetricPlotItem = {
              sensorId,
              varName,
              sensorName: sensorsById.get(sensorId)?.name ?? sensorId,
              metrics: [{ id: sensorId }],
              color: chartColors[selectedSensorIds.indexOf(sensorId) % chartColors.length],
            };
            return dataItem;
          });
        newItems.push(promisedItem as Promise<SensorMetricPlotItem>);
      });
      if (addGhosts) {
        const offsetTimems = weekCount * 7 * 24 * 3600 * 1000;
        filteredIds.forEach((sensorId) => {
          const promisedGhost = dataStore
            .getSensorDailyMetricHistory(
              sensorId,
              varName,
              new Date(startDate).getTime() - offsetTimems,
              new Date(endDate).getTime() - offsetTimems
            )
            .then((history) => {
              const processedData = processRawData(
                history,
                getHourSet(selectHours, startHour, endHour),
                metricSelection === MetricOptions.Hourly,
                weekCount
              );
              const dataItem: SensorMetricPlotItem = {
                sensorId: `${sensorId}-GHOST`,
                varName,
                sensorName: `${sensorsById.get(sensorId)?.name ?? sensorId}-GHOST`,
                metrics: processedData,
                color: chartColors[selectedSensorIds.indexOf(sensorId) % chartColors.length],
                ghost: true,
              };
              return dataItem;
            })
            .catch(() => {
              // If we can't find daily history use empty history
              const dataItem: SensorMetricPlotItem = {
                sensorId: `${sensorId}-GHOST`,
                varName,
                sensorName: `${sensorsById.get(sensorId)?.name ?? sensorId}-GHOST`,
                metrics: [{ id: sensorId }],
                color: chartColors[selectedSensorIds.indexOf(sensorId) % chartColors.length],
                ghost: true,
              };
              return dataItem;
            });
          newItems.push(promisedGhost as Promise<SensorMetricPlotItem>);
        });
      }
    });
    // Wait for the data and update
    Promise.all(newItems)
      .then((values) => {
        setFetchingSensorData(false);
        setSensorData(values);
      })
      .catch(() => {
        setSensorData([]);
        setFetchingSensorData(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    endDate,
    selectedSensorIds,
    selectedVarNames,
    sensorsByVarName,
    startDate,
    selectedHours,
    metricSelection,
    addGhosts,
    weekCount,
  ]);

  // create unselected time ranges for the NON selected times to grey them out
  const unselectedTimes = useMemo(() => {
    const hTs = getUnselectedTimeRange(selectedHours, startDate, endDate, metricSelection);
    return hTs;
  }, [selectedHours, startDate, endDate, metricSelection]);

  return (
    <div style={{ height: '100%' }}>
      {fetchingSensorData && <LoaderWithBackdrop />}
      <StackedMetricPlot
        dataItems={sensorData}
        unselectedTimes={unselectedTimes}
        metricSelection={metricSelection}
      />
      <CompareReport sensorData={sensorData} fetchingSensorData={fetchingSensorData} />
    </div>
  );
}

export default MetricDataPlotWrapper;
