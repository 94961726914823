import React, { useState, useEffect } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import RouterIcon from '@mui/icons-material/Router';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Alert from '@mui/material/Alert';
import { sensorTypeDetails } from '../../utils/sensorProperties';
import useStyles from '../../styles/location';
import useGlobalStyles from '../../styles/index';
import { VisibleSubSensor } from '../../services/api';
import ConfigureSensorDialog from '../Dialog/ConfigureSensorDialog';
import Loading from '../../components/Loading';
import { AlertMsg } from './LocationProperties';
import { setCurrentLocation } from '../../state/actions';
import { GatewayVisibleSubsensors } from '../SensorConfig/helpers';
import RSSILookup from '../SensorConfig/RSSILookup';
import { getShortTimeStr } from '../../utils/functions';
import { useAppDispatch } from '../../state/store';

interface NewSensorsListProps {
  fetchingSensors: boolean;
  allSensors: VisibleSubSensor[];
  locationId: string;
  allGatewaySubSensor: GatewayVisibleSubsensors[];
  refreshNewSensors: () => void;
}

function NewSensorsList({
  fetchingSensors,
  allSensors,
  locationId,
  allGatewaySubSensor,
  refreshNewSensors,
}: NewSensorsListProps): JSX.Element {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useAppDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [configurationSensor, setConfigurationSensor] = useState<VisibleSubSensor>();
  const [alertMsg, setAlertMsg] = useState<AlertMsg>();

  const handleConfigureSensor = (id: string) => {
    setAlertMsg(undefined);
    setShowDialog(true);
    const sensorDetails = allSensors.find((sensor) => sensor.suggested_id === id);
    if (sensorDetails) {
      setConfigurationSensor(sensorDetails);
    }
  };

  const sensorCreatedHandler = () => {
    setAlertMsg({
      success: true,
      msg: 'Sensor created Successfully',
      alertType: 'success',
    });
    refreshNewSensors();
    dispatch(setCurrentLocation(locationId));
  };

  useEffect(() => {
    setAlertMsg({
      success: true,
      msg: '',
      alertType: 'success',
    });
  }, [configurationSensor]);

  return (
    <>
      {alertMsg?.msg && (
        <Alert
          severity={alertMsg.alertType ?? 'info'}
          onClose={() => setAlertMsg({ success: true, msg: '', alertType: 'success' })}
          className={classes.alertMsg}
        >
          {alertMsg?.msg}
        </Alert>
      )}
      {fetchingSensors ? (
        <Loading />
      ) : (
        allSensors.map((sensor) => {
          const Icon: typeof SvgIcon = sensor?.type
            ? sensorTypeDetails[sensor.type]?.icon ?? RouterIcon
            : RouterIcon;
          const lastSeenTime = getShortTimeStr(sensor.last_seen);
          if (sensor.suggested_id) {
            return (
              <Box key={sensor.suggested_id}>
                {configurationSensor === sensor && allGatewaySubSensor && (
                  <ConfigureSensorDialog
                    showDialog={showDialog}
                    sensorDetails={configurationSensor as VisibleSubSensor}
                    locationId={locationId}
                    cancelHandler={() => setShowDialog(false)}
                    refreshSensors={sensorCreatedHandler}
                    allGatewayVisibleSubSensors={allGatewaySubSensor}
                  />
                )}
                <Grid container spacing={2} alignItems="center">
                  <Grid item md={1} sm={1} xs={1}>
                    <Tooltip
                      title={`${
                        sensorTypeDetails[sensor.type]?.label ?? ''
                      }, Last Seen: ${lastSeenTime}`}
                    >
                      <Icon className={classes.icon} />
                    </Tooltip>
                  </Grid>
                  <Grid item md={4} sm={4} xs={5}>
                    <Typography variant="body1">{sensor?.address} </Typography>
                  </Grid>
                  <Grid item md={2} sm={2} xs={2}>
                    <RSSILookup RSSI={sensor.rssi} />
                  </Grid>
                  <Grid item md={2} sm={2} xs={2} className={`${globalClasses.unixTime}`}>
                    {lastSeenTime}
                  </Grid>
                  <Grid item md={3} sm={2} xs={2}>
                    <Button
                      startIcon={<AddBoxIcon />}
                      color="secondary"
                      onClick={() => handleConfigureSensor(sensor?.suggested_id)}
                    >
                      <span className={classes.configureText}>Configure</span>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            );
          }
          return null;
        })
      )}
    </>
  );
}

export default NewSensorsList;
