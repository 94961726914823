import { Store } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware, EpicMiddleware } from 'redux-observable';
import { useDispatch, useSelector } from 'react-redux';

import rootReducer from './reducers';
import rootEpic from './epics';
import { ActionTypes } from './actionTypes';
import { RootState } from './types';

const epicMiddleware: EpicMiddleware<ActionTypes, ActionTypes, RootState> = createEpicMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Ignore specific checks in state to allow and update map record as non-serialized value
      serializableCheck: false,
      immutableCheck: false,
    }).concat(epicMiddleware),
});

// Run the root epic after store creation
epicMiddleware.run(rootEpic);

// Get the type of our store variable
export type AppStore = Store<RootState, ActionTypes>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];

// Use throughout the app instead of plain `useDispatch` & `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
