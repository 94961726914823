import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Tooltip from '@mui/material/Tooltip';
import MenuList from '@mui/material/MenuList';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { SensorLatest } from '../../services/api';
import {
  getActiveMarker,
  getHighlightedItem,
  getSelectedSensorIds,
  getSensorsById,
  getSortBy,
} from '../../state/selectors';
import { getBandColor, getDataString, sortSensors, SensorFilteredStats, PlotType } from './helpers';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/calendarView';
import { setHighlightedItem, setSelectedSensors, setSortBy } from '../../state/actions';
import BaseArraySortMenu, { SortPropertyType } from '../HelperComponents/BaseArraySortMenu';
import { VarName, varNameDetails } from '../../utils/varNames';
import { SortType } from '../../state/types';
import HistogramPlot from '../Plots/HistogramPlot';
import ReportsAddCircleIcon from '../../styles/icons/ReportsAddCircleIcon';
import BandValueCheckbox from '../HelperComponents/BandValueCheckbox';
import SensorIcon from '../../styles/icons/SensorIcon';
import { useAppDispatch, useAppSelector } from '../../state/store';

interface CalendarViewSensorArrayProps {
  sensorIds: string[];
  sensorFilteredStats: Map<string, SensorFilteredStats>;
  valuesRange: [number, number];
  summaryType: PlotType;
}

function CalendarViewSensorArray({
  sensorIds,
  sensorFilteredStats,
  valuesRange,
  summaryType,
}: CalendarViewSensorArrayProps): JSX.Element {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const sortBy = useAppSelector(getSortBy);
  const highlightedItem = useAppSelector(getHighlightedItem);
  const activeMarker = useAppSelector(getActiveMarker);
  const selectedSensorIds = useAppSelector(getSelectedSensorIds);
  const sensorsById = useAppSelector(getSensorsById);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const sensors = sensorIds.map((id) => sensorsById.get(id) ?? ({ id } as SensorLatest));

  const onMenuClick = (navUrl: string) => {
    setMenuAnchor(null);
    navigate(navUrl);
  };

  useEffect(() => {
    const defaultSort: SortType = { property: 'name', ascending: true };
    if (sortBy !== defaultSort) dispatch(setSortBy(defaultSort));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedSensors = useMemo(
    () => sortSensors(sensors, sortBy, sensorFilteredStats),
    [sensors, sortBy, sensorFilteredStats]
  );

  const mouseEnter = (id: string) => {
    dispatch(setHighlightedItem({ id, varName: activeMarker, source: 'dashboardPanel' }));
  };

  const mouseLeave = () => {
    dispatch(setHighlightedItem({ id: '' }));
  };

  const selectSensorHandler = (id: string) => {
    const sensorsList = [...selectedSensorIds];
    const isSelectedSensor = sensorsList.some((sensor) => sensor === id);
    if (isSelectedSensor)
      dispatch(setSelectedSensors(sensorsList.filter((sensor) => sensor !== id)));
    else {
      sensorsList.push(id);
      dispatch(setSelectedSensors(sensorsList));
    }
  };

  const showValuePill = (value: number | undefined) => {
    let sensorValue = value;
    if (value && activeMarker === VarName.Co2ppm) sensorValue = Math.trunc(Number(value));
    const dataValueString = Number.isNaN(value) ? 'N/A' : getDataString(sensorValue, activeMarker);
    const color = getBandColor(value, activeMarker);
    return (
      <span
        className={`${globalClasses.pillLabel} ${classes.occupancyPill}`}
        style={{ background: color }}
      >
        {dataValueString}
        <span className={globalClasses.pillUnitLabel}>
          {dataValueString && !Number.isNaN(value) ? varNameDetails[activeMarker].metric : ''}
        </span>
      </span>
    );
  };

  const minMaxValue = useMemo(() => {
    let minValue: number | undefined;
    let maxValue: number | undefined;
    sortedSensors.forEach((sensor) => {
      const min = sensorFilteredStats.get(sensor.id)?.stats.maxMinAvg.min;
      const max = sensorFilteredStats.get(sensor.id)?.stats.maxMinAvg.max;
      if (min !== undefined && !Number.isNaN(min)) {
        if (minValue === undefined) minValue = min;
        else if (min < minValue) minValue = min;
      }
      if (max !== undefined && !Number.isNaN(max)) {
        if (maxValue === undefined) maxValue = max;
        else if (max > maxValue) maxValue = max;
      }
    });
    return { minValue, maxValue };
  }, [sensorFilteredStats, sortedSensors]);

  return (
    <div>
      <List>
        <ListItem sx={{ padding: '8px' }}>
          <Grid container spacing={1}>
            <Grid item md={1} sm={1} xs={2} sx={{ textAlign: 'center', alignSelf: 'center' }}>
              <Tooltip title="Add to compare" placement="top">
                <div>
                  <ReportsAddCircleIcon />
                </div>
              </Tooltip>
            </Grid>

            <Grid
              item
              md={2}
              sm={2}
              sx={{ alignSelf: 'center' }}
              className={globalClasses.hideInSmallScreen}
            >
              <Typography variant="h6">Name</Typography>
            </Grid>
            <Grid
              item
              md={1}
              sm={1}
              className={`${classes.centered} ${globalClasses.hideInSmallScreen}`}
            >
              <Typography variant="h6">{summaryType}</Typography>
            </Grid>
            <Grid
              item
              md={4}
              sm={4}
              xs={8}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              className={classes.centered}
            >
              {minMaxValue.maxValue && <Box>{showValuePill(minMaxValue.minValue)}</Box>}
              <Typography
                variant="h6"
                className={globalClasses.hideInSmallScreen}
                sx={{ alignSelf: 'center' }}
              >
                Histogram
              </Typography>
              {minMaxValue.maxValue && <Box>{showValuePill(minMaxValue.maxValue)}</Box>}
            </Grid>
            <Grid item md={4} sm={4} xs={1} sx={{ textAlign: 'end' }}>
              <BaseArraySortMenu sortProperties={[SortPropertyType.name, SortPropertyType.value]} />
            </Grid>
          </Grid>
        </ListItem>
        {sortedSensors.length > 0 ? (
          sortedSensors?.map((sensor) => {
            const summaryValue = sensorFilteredStats.get(sensor.id)?.summaryValue;
            const color = getBandColor(summaryValue, activeMarker);
            const isSelectedSensor = selectedSensorIds.some((id) => id === sensor.id);
            const sensorStats = sensorFilteredStats.get(sensor.id)?.stats;
            let inlineValues;
            if (sensorStats?.totalAdded !== undefined) {
              inlineValues = sensorStats?.values?.totVals;
            } else if (sensorStats?.utl !== undefined) {
              inlineValues = sensorStats?.values?.utlVals.map((v) => v * 100);
            } else {
              inlineValues = sensorStats?.values?.avgVals;
            }

            return (
              <ListItem key={sensor.id} sx={{ padding: '4px' }}>
                <ListItemButton
                  style={{
                    backgroundColor:
                      sensor.id === highlightedItem.id || (!highlightedItem.id && isSelectedSensor)
                        ? `${color}66`
                        : theme.palette.primary.light,
                    borderRadius: '8px',
                    padding: '5px',
                  }}
                  onClick={() => selectSensorHandler(sensor.id)}
                  onMouseEnter={() => mouseEnter(sensor.id)}
                  onMouseLeave={mouseLeave}
                  onFocus={() => mouseEnter(sensor.id)}
                  onBlur={mouseLeave}
                >
                  <Grid container spacing={1}>
                    <Grid item md={1} sm={1} xs={2} sx={{ textAlign: 'center' }}>
                      <BandValueCheckbox sensorId={sensor.id} color={color} />
                    </Grid>
                    <Grid item md={2} sm={2} xs={5} sx={{ alignSelf: 'center' }}>
                      <Typography variant="body1">{sensor.name}</Typography>
                    </Grid>
                    <Grid
                      item
                      md={1}
                      sm={1}
                      xs={5}
                      sx={{ textAlign: { xs: 'end', sm: 'center' }, alignSelf: 'center' }}
                    >
                      {showValuePill(summaryValue)}
                    </Grid>
                    <Grid item md={6} sm={6} xs={10} className={classes.centered}>
                      <HistogramPlot
                        values={inlineValues !== undefined ? inlineValues : []}
                        sensorName={sensor.name ?? 'UNKNOWN'}
                        varName={activeMarker}
                        xRange={valuesRange}
                      />
                    </Grid>
                    <Grid item md={2} sm={2} xs={2} sx={{ textAlign: 'end' }}>
                      <IconButton
                        onClick={(event) => setMenuAnchor(event.currentTarget)}
                        sx={{ background: 'transparent' }}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </Grid>
                    <Menu
                      id="settings-menu"
                      anchorEl={menuAnchor}
                      open={Boolean(menuAnchor)}
                      onClose={() => setMenuAnchor(null)}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                      <MenuList sx={{ padding: 0 }}>
                        <MenuItem onClick={() => onMenuClick(`/sensors/${sensor.id}`)}>
                          <SensorIcon />
                          <span style={{ marginLeft: '5px' }}>Sensor Info</span>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Grid>
                </ListItemButton>
              </ListItem>
            );
          })
        ) : (
          <Typography variant="body1">No sensor data found</Typography>
        )}
      </List>
    </div>
  );
}

export default CalendarViewSensorArray;
