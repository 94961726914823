import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import {
  useContextCalendars,
  useContextDays,
  useContextDatePickerOffsetPropGetters,
  useContextMonths,
  useContextMonthsPropGetters,
  DPMonth,
} from '@rehookify/datepicker';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Calendar from './Calendar';
import { setSelectedEndDate, setSelectedStartDate } from '../../state/actions';
import { CalendarPickerType } from './DateRangeContainer';
import { getSelectedEndDate, getSelectedStartDate } from '../../state/selectors';
import { useAppDispatch, useAppSelector } from '../../state/store';

dayjs.extend(isToday);

interface CalendarSelectionProps {
  pickerType: CalendarPickerType;
  setPickerType: (value: CalendarPickerType) => void;
}

function CalendarSelection({ pickerType, setPickerType }: CalendarSelectionProps) {
  const dispatch = useAppDispatch();
  const { calendars } = useContextCalendars();
  const { selectedDates } = useContextDays();
  const { addOffset, subtractOffset } = useContextDatePickerOffsetPropGetters();
  const { months } = useContextMonths();
  const { monthButton } = useContextMonthsPropGetters();
  const startDate = useAppSelector(getSelectedStartDate);
  const endDate = useAppSelector(getSelectedEndDate);

  const [start, end] = selectedDates;

  useEffect(() => {
    if (start && end) {
      const targetEndDate = dayjs(end).isToday()
        ? new Date(Date.now())
        : dayjs(end).endOf('day').toDate();

      if (start !== startDate) dispatch(setSelectedStartDate(start));
      // avoid infinite loop to be caused by updated timestamp through every second
      if (
        dayjs(targetEndDate).format('YYYY-MM-DDTHH:mm') !==
        dayjs(endDate).format('YYYY-MM-DDTHH:mm')
      )
        dispatch(setSelectedEndDate(targetEndDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end]);

  const year = months[0].$date.getFullYear();

  const handleMonthBtn = (dpMonth: DPMonth) => {
    const monthBtn = { ...monthButton(dpMonth) };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    monthBtn?.onClick();
  };

  return (
    <Box>
      {pickerType === CalendarPickerType.month && (
        <Box sx={{ width: '14rem' }}>
          <main>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <IconButton
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...subtractOffset({ years: 1 })}
                style={{
                  margin: '5px',
                  padding: '5px',
                }}
              >
                <ArrowLeftIcon />
              </IconButton>
              <Typography variant="h6" sx={{ textAlign: 'center', alignSelf: 'center' }}>
                {year}
              </Typography>
              <IconButton
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...addOffset({ years: 1 })}
                style={{
                  margin: '5px',
                  padding: '5px',
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            </Box>

            {months.map((dpMonth) => (
              <Button
                key={dpMonth.$date.toDateString()}
                sx={{ display: dpMonth.disabled ? 'none' : '' }}
                onClick={() => {
                  setPickerType(CalendarPickerType.days);
                  handleMonthBtn(dpMonth);
                }}
              >
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <p>{dayjs(dpMonth.$date).format('MMM')}</p>
              </Button>
            ))}
          </main>
        </Box>
      )}
      {pickerType === CalendarPickerType.days && (
        <Box
          sx={{
            display: { xs: 'block', sm: 'flex' },
            columnGap: '2rem',
            justifyContent: 'center',
          }}
        >
          <Calendar
            prevButton={
              <IconButton
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...subtractOffset({ months: 1 })}
                style={{
                  margin: '5px',
                  padding: '5px',
                }}
              >
                <ArrowLeftIcon />
              </IconButton>
            }
            calendar={calendars[2]}
            setPickerType={setPickerType}
          />
          <Calendar
            nextButton={
              <IconButton
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...addOffset({ months: 1 })}
                style={{
                  margin: '5px',
                  padding: '5px',
                }}
              >
                <ArrowRightIcon />
              </IconButton>
            }
            calendar={calendars[0]}
            setPickerType={setPickerType}
          />
        </Box>
      )}
    </Box>
  );
}

export default CalendarSelection;
