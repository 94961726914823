import React, { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router';
import Typography from '@mui/material/Typography';
import posthog from 'posthog-js';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import LanguageIcon from '@mui/icons-material/Language';

import {
  getActiveMarker,
  getLocationsById,
  getMotionThreshold,
  getSensorsById,
  getUserPosition,
} from '../../../state/selectors';
import {
  BandParamsType,
  getDataBandParams,
  varNameBandParams,
} from '../../../utils/dataBandParams';
import { themeProps } from '../../../styles/theme';
import { varNameDetails } from '../../../utils/varNames';
import { getDataValueString } from '../../../Widgets/HelperComponents/DataValueString';
import { useAppSelector } from '../../../state/store';
import {
  ContactType,
  createEmailTemplate,
  ContactMethod,
  GenericContactDetails,
  TFLContactDetails,
} from './ContactDetails';

interface FeedbackProps {
  sensorId: string;
  reset: () => void;
}

function Feedback({ sensorId, reset }: FeedbackProps) {
  const theme = useTheme();
  const activeMarker = useAppSelector(getActiveMarker);
  const sensorsById = useAppSelector(getSensorsById);
  const locationsById = useAppSelector(getLocationsById);
  const motionThreshold = useAppSelector(getMotionThreshold);
  const userPosition = useAppSelector(getUserPosition);
  const bands = varNameBandParams[activeMarker];
  const [selectedBand, setSelectedBand] = useState<BandParamsType>();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const sensorDetails = sensorsById.get(sensorId) ?? { id: sensorId };
  const sensorDataItem = sensorDetails.data?.find((item) => item.varName === activeMarker);
  const sensorValue = sensorDataItem?.value ?? 0;
  const dataValueString = getDataValueString(
    sensorValue,
    activeMarker,
    sensorDataItem?.time,
    motionThreshold
  );

  const { label, color } = getDataBandParams(activeMarker, sensorValue);

  const { location, name } = sensorDetails;

  const locFullName = useMemo(() => {
    let loc = location;
    const propLocItem = locationsById.get(location ?? '');
    const locName = propLocItem?.name;
    if (location && location === '#') loc = locName;
    else if (location && location.startsWith('#')) {
      const parentLocName = locationsById.get(propLocItem?.location ?? '')?.name;
      loc = `${parentLocName}, ${locName}`;
    }

    return loc ?? '';
  }, [location, locationsById]);

  const handleSubmit = () => {
    setShowDialog(true);
    posthog.capture('App Feedback', {
      sensorId,
      sensorName: name,
      userPosition,
      sensorLocation: locFullName ?? sensorDetails?.location, // set full location e.g. 5 Endeavour Square, 10th Floor
      locationId: sensorDetails?.location,
      varName: activeMarker,
      value: dataValueString,
      feedBackSelection: selectedBand?.label,
    });
  };

  const emailSubject = `${varNameDetails[activeMarker].label} ${name} ${selectedBand?.label}`;

  const getContactDetails = () => {
    let contactDetails = GenericContactDetails;
    const isTFLLoc = locationsById.get('#')?.name === '14 Pier Walk'; // Todo: make it dynamic through API where # to be replaced with locationId
    if (isTFLLoc) contactDetails = TFLContactDetails;

    return contactDetails;
  };

  const contactDetails = getContactDetails();
  const { preferredText, contactMethods } = contactDetails;

  // move preferred method to beginning
  if (contactMethods) {
    const preferredMethodIndex = contactMethods.findIndex((item) => item.isPreferred === true);
    contactMethods.unshift(contactMethods.splice(preferredMethodIndex, 1)[0]);
  }

  const showContactInfo = (contactMethod: ContactMethod) => {
    let Icon = EmailIcon;
    let url = '';

    switch (contactMethod.type) {
      case ContactType.email:
        url = createEmailTemplate(
          contactDetails.name,
          contactMethod.value,
          emailSubject,
          locFullName,
          name ?? '' // using sensor name as category
        );
        break;
      case ContactType.phone:
        Icon = PhoneIphoneIcon;
        url = `tel:${contactMethod.value}`;
        break;
      case ContactType.web:
        Icon = LanguageIcon;
        url = contactMethod.value;
        break;
      default:
        break;
    }

    return (
      <Box
        key={contactMethod.type}
        sx={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}
      >
        <br />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = url;
            e.preventDefault();
          }}
          style={{ color: theme.palette.text.primary, display: 'flex' }}
        >
          <Icon /> {contactMethod.displayValue}{' '}
        </Link>
        {contactMethod.additionalInfo && <span>&nbsp;({contactMethod.additionalInfo})</span>}
      </Box>
    );
  };

  return (
    <Box sx={{ padding: '2rem 8px', height: '100%', overflowY: 'scroll' }}>
      <Dialog
        open={showDialog}
        style={{ textAlign: 'center' }}
        onClose={() => setShowDialog(false)}
      >
        <DialogContent>
          Thank you for your feedback. This will be used to help improve building operations in the
          long term.
          <br /> <br />
          {preferredText}
          <br />
          {contactMethods && contactMethods?.map((method) => showContactInfo(method))}
          <br />
          If you would like to share feedback about the app itself please contact the LightFi team
          via email {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            to="#"
            onClick={(e) => {
              window.location.href = 'mailto:support@lightfi.io';
              e.preventDefault();
            }}
            style={{ color: theme.palette.text.primary }}
          >
            support@lightfi.io
          </Link>
          <br /> <br /> <b>Detail:</b>
          <br /> You are currently near <b>{name}</b> at{' '}
          <b>{dayjs().format('MMM DD, YYYY HH:mm')}</b> and feel the{' '}
          {varNameDetails[activeMarker].label} is <b>{selectedBand?.label}</b>
          <br />
          <Button
            variant={themeProps.btnVariant.default}
            sx={{
              margin: '10px',
              width: '50%',
              background: themeProps.colors.warningRed,
              border: 'none',
            }}
            onClick={() => {
              setShowDialog(false);
              reset();
            }}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>

      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        {name ?? sensorId}
      </Typography>
      <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '2rem' }}>
        {varNameDetails[activeMarker].label} is currently {dataValueString} which is meant to be{' '}
        <span style={{ color }}>{label}</span>
      </Typography>
      <hr style={{ width: '50%', marginTop: '1rem' }} />
      <Typography variant="h4" sx={{ textAlign: 'center', marginTop: '2rem' }}>
        I feel the {varNameDetails[activeMarker].label} is:
      </Typography>
      <Box sx={{ marginTop: '2rem' }}>
        {bands &&
          bands.map((band) => (
            <Button
              key={band.label}
              variant={themeProps.btnVariant.default}
              sx={{
                marginTop: '1rem',
                borderRadius: '20px',
                background:
                  band === selectedBand ? themeProps.colors.green : theme.palette.primary.light,
                border: 'none',
                width: '100%',
                padding: '10px 0',
              }}
              onClick={() => setSelectedBand(band)}
            >
              {band.label}
            </Button>
          ))}

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
          <Button
            variant={themeProps.btnVariant.default}
            sx={{
              margin: '10px',
              width: '50%',
              background: themeProps.colors.happyGreen,
              border: 'none',
              padding: '15px',
            }}
            onClick={handleSubmit}
            disabled={!selectedBand}
          >
            Submit
          </Button>
          <Button
            variant={themeProps.btnVariant.default}
            sx={{
              margin: '10px',
              width: '50%',
              background: themeProps.colors.warningRed,
              border: 'none',
            }}
            onClick={reset}
          >
            Back
          </Button>
        </Box>
        <span>
          Submitting feedback is anonymous. We do not capture any personal data from you or your
          device.
        </span>
      </Box>
    </Box>
  );
}

export default Feedback;
