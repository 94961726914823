import React, { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TuneIcon from '@mui/icons-material/Tune';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { VarName, varNameDetails } from '../utils/varNames';
import { persistState, StorageTypes } from '../utils/persistentState';
import { getActivePlotVars, getAvailableVars, getSelectedVars } from '../state/selectors';
import { setSensorVars, toggleActivePlotVar } from '../state/actions';
import useStyles from '../styles';
import Help from './Help';
import { themeProps } from '../styles/theme';
import { useAppDispatch, useAppSelector } from '../state/store';

interface SourceMenuProps {
  helpPosition?: 'top' | 'bottom' | 'left' | 'right';
  iconOnlyBtn?: boolean;
  customIcon?: React.ReactElement;
}

function SourcesMenu({ helpPosition, iconOnlyBtn, customIcon }: SourceMenuProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [anchorConfig, setAnchorConfig] = useState<null | HTMLElement>(null);
  const selectedVars = useAppSelector(getSelectedVars);
  const activePlotVars = useAppSelector(getActivePlotVars);
  const availableVars = useAppSelector(getAvailableVars);

  const handleOpenConfig = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorConfig(event.currentTarget);
  };

  const handleCloseConfig = () => {
    setAnchorConfig(null);
  };

  useEffect(() => {
    if (availableVars.length > 0) {
      // update activePlot vars if missing in available vars
      const missingPlotVars = activePlotVars.filter((item) => !availableVars.includes(item));
      missingPlotVars.forEach((vars) => dispatch(toggleActivePlotVar(vars)));

      // update selected vars if missing in available vars
      const filteredSelectedVars = selectedVars.filter((item) => availableVars.includes(item));
      if (filteredSelectedVars.length !== selectedVars.length) {
        dispatch(setSensorVars(filteredSelectedVars));
        persistState(filteredSelectedVars, StorageTypes.SelectedVars);
      }
    }
  }, [availableVars, activePlotVars, selectedVars, dispatch]);

  const handleToggleConfigOption = (option: VarName) => {
    let newVars;
    if (selectedVars.indexOf(option) === -1) {
      newVars = [...selectedVars, option];
      dispatch(toggleActivePlotVar(option));
    } else {
      newVars = selectedVars.filter((item) => item !== option);
    }

    persistState(newVars, StorageTypes.SelectedVars);
    dispatch(setSensorVars(newVars));
  };

  return (
    <Help helpText="Add/remove data sources like CO2 or Temperature" position={helpPosition}>
      <div className={classes.sourcesMenu}>
        {iconOnlyBtn ? (
          <Tooltip title="Change sources" placement={helpPosition}>
            <IconButton onClick={handleOpenConfig} color="inherit" size="large">
              {customIcon ?? <AddCircleIcon />}
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            startIcon={<TuneIcon />}
            onClick={handleOpenConfig}
            color="inherit"
            size="small"
            variant={themeProps.btnVariant.text}
            className={classes.sourceBtn}
          >
            <span>Sources</span>
          </Button>
        )}
        <Menu
          id="varname-menu"
          anchorEl={anchorConfig}
          keepMounted
          open={Boolean(anchorConfig)}
          onClose={handleCloseConfig}
        >
          {Object.values(varNameDetails)
            .filter(({ id }) => id !== VarName.Unknown)
            .sort((a, b) => {
              const va = a.label;
              const vb = b.label;
              if (va < vb) return -1;
              if (va > vb) return 1;
              return 0;
            })
            .map((option) => {
              const { id, label, icon } = option;
              const Icon = icon;
              return (
                <MenuItem
                  key={`config-menu-${id}`}
                  selected={selectedVars.indexOf(id) !== -1}
                  onClick={() => handleToggleConfigOption(option.id)}
                  disabled={availableVars.indexOf(id) === -1}
                  // hide unavailableVars from menu in mobile view
                  sx={{
                    display: {
                      xs: availableVars.indexOf(id) === -1 ? 'none' : 'block',
                      sm: 'block',
                    },
                  }}
                  className={classes.sourceMenuItem}
                >
                  <span
                    style={{
                      display: 'block',
                      padding: '5px',
                    }}
                  >
                    {Icon && (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Icon style={{ marginRight: '0.5rem' }} />
                        {label}
                      </span>
                    )}
                    {!Icon && label}
                  </span>
                </MenuItem>
              );
            })}
        </Menu>
      </div>
    </Help>
  );
}

SourcesMenu.defaultProps = {
  helpPosition: 'left',
  iconOnlyBtn: undefined,
  customIcon: undefined,
};

export default SourcesMenu;
