import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import useStyles from '../../styles/location';
import { AlertMsg, AccessLevelType } from './LocationProperties';
import { addNewUser } from '../../services/apiService';
import { NewUserDetails } from '../../services/api';
import { getLocationsById } from '../../state/selectors';
import { themeProps } from '../../styles/theme';
import { useAppSelector } from '../../state/store';

interface CreateNewUserProps {
  locationId: string;
  setIsCreatingUser: (value: boolean) => void;
  refreshUsers: () => void;
}

function CreateNewUser({
  locationId,
  setIsCreatingUser,
  refreshUsers,
}: CreateNewUserProps): JSX.Element {
  const classes = useStyles();
  const [alertMsg, setAlertMsg] = useState<AlertMsg | undefined>();
  const [accessLevel, setAccessLevel] = useState<string | unknown>('readOnly');
  const [email, setEmail] = useState<string>('');
  const locationsById = useAppSelector(getLocationsById);
  const currentLocationName = locationsById.get(locationId)?.name ?? locationId;

  const addUser = () => {
    const newUserProps = {
      access_level: accessLevel,
      email,
    };
    const emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(email)) {
      addNewUser(locationId, newUserProps as NewUserDetails)
        .then(() => {
          refreshUsers();
          setAlertMsg({ success: true, msg: 'User successfully created', alertType: 'success' });
          setEmail('');
        })
        .catch((err) => setAlertMsg({ success: false, msg: err.cause, alertType: 'error' }));
    } else {
      setAlertMsg({ success: false, msg: 'Email is not in valid format', alertType: 'error' });
    }
  };

  return (
    <div className={classes.createUserContainer}>
      <Typography variant="h6" style={{ marginBottom: '10px' }}>
        Add New User
      </Typography>
      <TextField
        label="Location"
        value={currentLocationName}
        disabled
        className={classes.newUserTextField}
        variant="standard"
      />
      <TextField
        type="email"
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className={classes.newUserTextField}
        variant="standard"
      />
      <Box mt="10px" mb="5px">
        <Select
          labelId="Access Level"
          onChange={(e) => setAccessLevel(e.target.value)}
          value={accessLevel}
          className={classes.newUserTextField}
          variant="standard"
        >
          {Object.entries(AccessLevelType).map((accessType) => (
            <MenuItem value={accessType[1]} key={accessType[0]}>
              {accessType[0]}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {alertMsg?.msg && (
        <Alert
          severity={alertMsg.alertType ?? 'info'}
          onClose={() => setAlertMsg({ success: true, msg: '', alertType: 'success' })}
          className={classes.alertMsg}
        >
          {alertMsg?.msg}
        </Alert>
      )}
      <Box mt={2}>
        <Button variant={themeProps.btnVariant.default} onClick={addUser}>
          Add
        </Button>
        <Button className={classes.cancelBtn} onClick={() => setIsCreatingUser(false)}>
          Close
        </Button>
      </Box>
    </div>
  );
}

export default CreateNewUser;
