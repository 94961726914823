import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { useParams, useLocation } from 'react-router';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import IconButton from '@mui/material/IconButton';

import useGlobalStyles from '../styles';
import useStyles from '../styles/dashboard';
import { appSwitchBtnHandler } from '../Shell/helpers';
import LFSwitchLogoIcon from '../styles/icons/LFSwitchLogoIcon';
import MapGL from '../Widgets/Map/Map';
import { MapCentrePosition } from '../Widgets/Map/mapHelpers';
import {
  goFetchRefresh,
  setBleLocSwitchStatus,
  setBleScanStatus,
  setClickedItem,
  setShowNavDrawer,
} from '../state/actions';
import {
  getActiveMarker,
  getBleLocSwitchStatus,
  getBleScanStatus,
  getBleSensors,
  getClickedItem,
  getCurrentLocation,
  getCurrentLocationChildSensors,
  getHighlightedItem,
  getLocationsById,
  getSensorsById,
  getShowNavDrawer,
} from '../state/selectors';
import Header from '../components/Header';
import SensorHistory from '../Widgets/Dashboard/SensorHistory';
import DashboardRingPlots from '../Widgets/Dashboard/DashboardRingPlots';
import { themeProps } from '../styles/theme';
import ColouredLinePlotWrapper from '../Widgets/HelperComponents/ColouredLinePlotWrapper';
import MobileLocationSwitcher from '../Widgets/Dashboard/MobileLocationSwitcher';
import { useAppDispatch, useAppSelector } from '../state/store';

function Dashboard(): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { sensorId } = useParams() as { sensorId: string };
  const isDesktopView = useMediaQuery(theme.breakpoints.up('sm'));
  const activeMarker = useAppSelector(getActiveMarker);
  const locationsById = useAppSelector(getLocationsById);
  const showNavDrawer = useAppSelector(getShowNavDrawer);
  const currentLocation = useAppSelector(getCurrentLocation);
  const highlightedItem = useAppSelector(getHighlightedItem);
  const clickedItem = useAppSelector(getClickedItem);
  const sensorsById = useAppSelector(getSensorsById);
  const bleScanStatus = useAppSelector(getBleScanStatus);
  const locSensors = useAppSelector(getCurrentLocationChildSensors);
  const allowBleLocSwitch = useAppSelector(getBleLocSwitchStatus);
  const bleSensors = useAppSelector(getBleSensors);

  const [showSensorHistory, setShowSensorHistory] = useState(!!sensorId);
  const [anchorConfig, setAnchorConfig] = useState<null | HTMLElement>(null); // custom location switcher for mobile view

  const activeItem = highlightedItem.id ? highlightedItem : clickedItem;
  const activeSensorDetail = sensorsById.get(activeItem.id);

  // remember to remove appSourceMenu in future
  const isAppDashboard =
    pathname.startsWith('/appDashboard') ||
    pathname.startsWith('/appSourceMenu') ||
    bleSensors !== undefined;

  // clear clicked item when location changes
  useEffect(() => {
    dispatch(setClickedItem({ id: '' }));
  }, [currentLocation, dispatch]);

  // if sensor Id is passed from URL, set it as clicked item and display history
  useEffect(() => {
    if (sensorId) {
      setShowSensorHistory(true);
      dispatch(setClickedItem({ id: sensorId, varName: activeMarker }));
    } else {
      setShowSensorHistory(false);
      dispatch(setClickedItem({ id: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorId]);

  const handleScan = () => {
    dispatch(goFetchRefresh());
    dispatch(setBleScanStatus(true));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.ReactNativeWebView?.postMessage(JSON.stringify({ startScan: 'startScan' }));
  };

  const showSensorGraph = useMemo(() => {
    let showGraph = false;
    // show graph for sunburst hover even when the hovered sensor is not from current location
    if (isDesktopView && !showSensorHistory && activeItem.id) showGraph = true;

    return showGraph;
  }, [activeItem.id, isDesktopView, showSensorHistory]);

  return (
    <div
      style={{ overflowY: 'hidden', height: '100%', padding: '0' }}
      className={globalClasses.bodyContent}
    >
      <Box
        sx={{
          position: 'relative',
          height: '100%',
        }}
      >
        {/* show header items based on portal or app dashboard */}
        {isAppDashboard ? (
          <Box sx={{ display: isAppDashboard ? 'flex' : 'none' }} className={classes.appSwitchBtn}>
            <Switch
              checked
              onClick={() => {
                appSwitchBtnHandler(dispatch);
                dispatch(setClickedItem({ id: '' }));
              }}
              color="success"
              checkedIcon={<LFSwitchLogoIcon fontSize="small" />}
            />
          </Box>
        ) : (
          <>
            {isDesktopView && (
              <Box className={classes.floatingHeader}>
                <Header showSearchField={false} />
              </Box>
            )}
            <Box
              sx={{ display: { xs: 'block', sm: 'none', zIndex: 10 } }}
              className={classes.appSwitchBtn}
            >
              <IconButton onClick={() => dispatch(setShowNavDrawer(!showNavDrawer))}>
                <MenuIcon />
              </IconButton>
            </Box>
          </>
        )}

        {/* body content */}
        <Box className={classes.dashboardContent} sx={{ zIndex: showSensorHistory ? 100 : 11 }}>
          {showSensorHistory ? (
            <SensorHistory
              goToDashboard={() => setShowSensorHistory(false)}
              isAppDashboard={isAppDashboard}
            />
          ) : (
            // display selected sensor overview for mobile view
            <>
              {!isDesktopView && (
                <Box sx={{ display: 'flex', marginTop: '55px', alignItems: 'center' }}>
                  <MobileLocationSwitcher
                    anchorConfig={anchorConfig}
                    hideSwitcher={() => setAnchorConfig(null)}
                  />
                  <IconButton
                    onClick={(e: React.MouseEvent<HTMLElement>) => setAnchorConfig(e.currentTarget)}
                    sx={{ background: 'none' }}
                  >
                    <LocationOnIcon
                      sx={{ fontSize: '50px', color: themeProps.colors.warningRed }}
                    />
                  </IconButton>

                  <Box>
                    {activeSensorDetail?.name ? (
                      <Typography variant="h4">{activeSensorDetail.name}</Typography>
                    ) : (
                      <Typography variant="h4">
                        {locSensors.length > 0 ? 'Select sensor' : 'Select Location'}
                      </Typography>
                    )}
                    <Typography variant="body1">
                      {locationsById.get(currentLocation)?.name}
                    </Typography>
                  </Box>
                  {/*  display scan spinner for app */}
                  {isAppDashboard && (
                    <Box sx={{ marginLeft: '10px' }}>
                      {!allowBleLocSwitch && (
                        <IconButton
                          sx={{ height: 'fit-content' }}
                          onClick={() => dispatch(setBleLocSwitchStatus(true))}
                        >
                          <MyLocationIcon sx={{ color: themeProps.colors.warningRed }} />{' '}
                        </IconButton>
                      )}
                      <IconButton
                        sx={{ height: 'fit-content', marginLeft: '10px' }}
                        onClick={handleScan}
                        className={bleScanStatus ? globalClasses.spinIcon : ''}
                      >
                        <AutorenewIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              )}
              {/* display doughnut plots or for mobile view plot/avgValue based on selected sensor */}
              <DashboardRingPlots showHistory={() => setShowSensorHistory(true)} />
            </>
          )}
        </Box>

        <MapGL
          showSourceToolbar={!isDesktopView}
          mapCentrePosition={isDesktopView ? MapCentrePosition.right : MapCentrePosition.bottom}
        />

        {/* show floating plot for active Item */}
        {showSensorGraph && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 10,
              right: 10,
              borderRadius: '8px',
              padding: '8px',
              width: '500px',
              background: theme.palette.primary.main,
              zIndex: 11,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6">{activeSensorDetail?.name}</Typography>
              <CloseIcon
                color="error"
                onClick={() => dispatch(setClickedItem({ id: '' }))}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <ColouredLinePlotWrapper
              varName={activeItem?.varName ?? activeMarker}
              sensorId={activeItem.id}
            />
          </Box>
        )}
      </Box>
    </div>
  );
}

export default Dashboard;
