import React, { ChangeEvent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/location';

import { AlertMsg, locationTypeDetail, LocationAction } from './LocationProperties';
import { PropLocationItem, PropLocationItemIn, LocationType } from '../../services/api/api';
import { deleteLocation, updateLocationProperties } from '../../services/apiService';
import { removeLocationData, updateLocationData } from '../../state/actions';
import RemoveDialog from '../Dialog/RemoveDialog';
import FloorPlanConfig from './FloorPlanConfig';
import AdvancedLocationDetails from './AdvancedLocationDetails';
import LocationDetailsItem from './LocationDetailsItem';
import EditBtnIcon from '../HelperComponents/EditBtnIcon';
import { useAppDispatch } from '../../state/store';

interface LocationDetailsProps {
  locationDetails: PropLocationItem;
  setLocationDetails: (details: PropLocationItem) => void;
  alertMsg: AlertMsg | undefined;
  setAlertMsg: (value: AlertMsg | undefined) => void;
  isAllowedToDelete: boolean;
}
function LocationDetails({
  locationDetails,
  setLocationDetails,
  alertMsg,
  setAlertMsg,
  isAllowedToDelete,
}: LocationDetailsProps): JSX.Element {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currentEvent, setCurrentEvent] = useState<LocationAction>(LocationAction.locationInfo);
  const [isEditing, setIsEditing] = useState(false);
  const [locationItem, setLocationItem] = useState<PropLocationItemIn>();
  const displayItems = ['name'];

  useEffect(() => {
    setIsEditing(false);
    setCurrentEvent(LocationAction.locationInfo);
  }, [locationDetails]);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, key: string) => {
    const { value } = e.target;
    const item = { ...locationItem, [key]: value };
    setLocationItem(item);
  };

  const handleUpdate = () => {
    setCurrentEvent(LocationAction.locationInfo);
    setIsEditing(false);
    if (locationItem) {
      updateLocationProperties(locationDetails.id, locationItem)
        .then((response) => {
          if (response?.id) {
            setLocationDetails(response);
            dispatch(updateLocationData(response));
            setAlertMsg({ success: true, msg: 'Update successful', alertType: 'success' });
            setLocationItem(undefined);
          }
        })
        .catch((err) => {
          setAlertMsg({ success: false, msg: err.cause, alertType: 'error' });
        });
    }
  };

  const removeLocation = () => {
    const forceDelete = true;
    deleteLocation(locationDetails.id, forceDelete)
      .then(() => {
        dispatch(removeLocationData(locationDetails.id));
        navigate(`/location/${encodeURIComponent(locationDetails?.location ?? '#')}`);
        setAlertMsg({ success: true, msg: 'Location has been Removed', alertType: 'success' });
        setCurrentEvent(LocationAction.locationInfo);
      })
      .catch((err) => {
        setAlertMsg({ success: false, msg: err.cause, alertType: 'error' });
        setCurrentEvent(LocationAction.locationInfo);
      });
  };

  const handleEdit = (editing: boolean) => {
    setCurrentEvent(LocationAction.locationInfo);
    setLocationItem(undefined);
    setIsEditing(editing);
  };

  return (
    <>
      {alertMsg?.msg && (
        <Alert
          severity={alertMsg.alertType ?? 'info'}
          onClose={() => setAlertMsg({ success: true, msg: '', alertType: 'success' })}
          className={globalClasses.alertMsg}
        >
          {alertMsg?.msg}
        </Alert>
      )}
      <RemoveDialog
        showDialog={currentEvent === LocationAction.removeLocation}
        title="location"
        removeHandler={removeLocation}
        cancelHandler={() => setCurrentEvent(LocationAction.locationInfo)}
      />
      <List>
        <ListItem>
          <Grid item sm={8} xs={8} style={{ display: 'flex' }}>
            <InfoIcon className={classes.icon} style={{ alignSelf: 'center' }} />
            <Typography variant="h6" style={{ marginLeft: '10px' }}>
              Location Details
            </Typography>
          </Grid>
          <Tooltip title="edit">
            <Grid
              item
              sm={4}
              xs={4}
              style={{ textAlign: 'right', display: 'flex', justifyContent: 'end' }}
            >
              <EditBtnIcon
                isEditing={isEditing}
                handleEdit={handleEdit}
                changedItem={locationItem}
                handleUpdate={handleUpdate}
              />
              {isEditing && isAllowedToDelete && (
                <IconButton
                  onClick={() => setCurrentEvent(LocationAction.removeLocation)}
                  size="large"
                >
                  <DeleteIcon className={globalClasses.closeBtnIcon} />
                </IconButton>
              )}
            </Grid>
          </Tooltip>
        </ListItem>
        <LocationDetailsItem
          locationDetails={locationDetails}
          displayItems={displayItems}
          isEditing={isEditing}
          onChange={onChange}
        />
        <ListItem>
          <Grid item sm={6} xs={6}>
            <ListItemText primary="Type" />
          </Grid>
          {isEditing ? (
            <Grid item sm={6} xs={6}>
              <Select
                labelId="location-type"
                defaultValue={locationDetails.type}
                style={{ width: '100%' }}
                onChange={(e) =>
                  onChange(e as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, 'type')
                }
                variant="standard"
              >
                {Object.entries(LocationType).map((locationType) => (
                  <MenuItem value={locationType[1]} key={locationType[0]}>
                    {locationType[0]}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          ) : (
            <ListItemText
              primary={locationDetails.type && locationTypeDetail[locationDetails.type].label}
            />
          )}
        </ListItem>
        <AdvancedLocationDetails locationDetails={locationDetails} />
        <FloorPlanConfig locationId={locationDetails.id} />
      </List>
    </>
  );
}

export default LocationDetails;
