/* eslint-disable react/destructuring-assignment */
import React, { useState, ChangeEvent, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { PropLocationItem, PropLocationItemIn } from '../../services/api';
import useStyles from '../../styles/location';
import { AlertMsg } from './LocationProperties';
import { updateLocationProperties } from '../../services/apiService';
import { updateLocationData } from '../../state/actions';
import LocationDetailsItem from './LocationDetailsItem';
import EditBtnIcon from '../HelperComponents/EditBtnIcon';
import { useAppDispatch } from '../../state/store';

const advancedDetailsItems = ['id', 'shortName', 'location', 'latest', 'timeZone'];

interface AdvancedLocationDetailsProps {
  locationDetails: PropLocationItem;
}

function AdvancedLocationDetails({ locationDetails }: AdvancedLocationDetailsProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [alertMsg, setAlertMsg] = useState<AlertMsg | undefined>();
  const [locationItem, setLocationItem] = useState<PropLocationItemIn>();
  const [location, setLocation] = useState<PropLocationItem>();

  useEffect(() => {
    setAlertMsg(undefined);
    setLocation(locationDetails);
  }, [locationDetails]);

  const handleUpdate = () => {
    setIsEditing(false);
    if (locationItem) {
      updateLocationProperties(locationDetails.id, locationItem)
        .then((response) => {
          setLocation(response);
          dispatch(updateLocationData(response));
          setAlertMsg({ success: true, msg: 'Update successful', alertType: 'success' });
          setLocationItem(undefined);
        })
        .catch((err) => setAlertMsg({ success: false, msg: err.cause, alertType: 'error' }));
    }
  };

  const handleEdit = (editing: boolean) => {
    setLocationItem(undefined);
    setIsEditing(editing);
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, key: string) => {
    const { value } = e.target;
    const item = { ...locationItem, [key]: value };
    setLocationItem(item);
  };

  return (
    <ListItem>
      <Accordion style={{ width: '100%' }} expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => setExpanded(!expanded)} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignSelf: 'center' }}>
              <SettingsIcon className={classes.icon} />
              <Typography style={{ alignSelf: 'center', marginLeft: '10px' }}>
                Advanced Details
              </Typography>
            </div>
            {expanded && (
              <EditBtnIcon
                isEditing={isEditing}
                handleEdit={handleEdit}
                changedItem={locationItem}
                handleUpdate={handleUpdate}
              />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <>
            {alertMsg?.msg && (
              <Alert
                severity={alertMsg.alertType ?? 'info'}
                onClose={() => setAlertMsg({ success: true, msg: '', alertType: 'success' })}
                className={classes.alertMsg}
              >
                {alertMsg?.msg}
              </Alert>
            )}
            {location && (
              <List>
                <LocationDetailsItem
                  locationDetails={location}
                  displayItems={advancedDetailsItems}
                  isEditing={isEditing}
                  onChange={onChange}
                />
              </List>
            )}
          </>
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
}

export default AdvancedLocationDetails;
