import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { MobileLogo } from './Logo';
import { setShowNavDrawer } from '../state/actions';
import { getShowNavDrawer } from '../state/selectors';
import { useAppDispatch, useAppSelector } from '../state/store';

function DefaultMobileHeader(): JSX.Element {
  const dispatch = useAppDispatch();
  const showNavDrawer = useAppSelector(getShowNavDrawer);

  return (
    <Box
      sx={{
        display: { md: 'none', xs: 'flex' },
        justifyContent: 'space-between',
        padding: '10px',
      }}
    >
      <MobileLogo />
      <IconButton
        style={{ paddingTop: '5px' }}
        onClick={() => dispatch(setShowNavDrawer(!showNavDrawer))}
      >
        <MenuIcon />
      </IconButton>
    </Box>
  );
}

export default DefaultMobileHeader;
