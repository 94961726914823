import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {
  PropSensorItemCreate,
  SensorLatest,
  VisibleSubSensor,
  GeoJSON,
  SubSensorType,
} from '../../services/api/api';
import { addNewSensor, fetchLocationFloorplan } from '../../services/apiService';
import { SensorPositionData } from '../Map/types';
import { PropSensorItemCreateIndexed } from '../../utils/sensorProperties';
import useStyles from '../../styles';
import PositioningMap from '../Map/PositioningMap';
import CreateSensorForm from './CreateSensorForm';
import { getAllLocationFloorplan } from '../../state/selectors';
import { AlertMsg } from '../LocationConfig/LocationProperties';
import { themeProps } from '../../styles/theme';
import { setLocationFloorplan, setSensorsById } from '../../state/actions';
import { useAppDispatch, useAppSelector } from '../../state/store';

interface CreateSensorProps {
  sensorGatewayDetails: SensorLatest;
  subSensorData: VisibleSubSensor;
  creatingSensor: (value: boolean) => void;
  refreshSubSensor: () => void;
  sensorNewId: string;
  enableSensor: (value: boolean) => void;
}

function CreateSensor({
  sensorGatewayDetails,
  subSensorData,
  creatingSensor,
  refreshSubSensor,
  sensorNewId,
  enableSensor,
}: CreateSensorProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const allFloorPlan = useAppSelector(getAllLocationFloorplan);
  const [sensorPayload, setSensorPayload] = useState<PropSensorItemCreateIndexed>();
  const [alertMsg, setAlertMsg] = useState<AlertMsg>();
  const [position, setPosition] = useState<SensorPositionData>({});
  const [positionValid, setPositionValid] = useState(true);
  const [floorPlan, setFloorPlan] = useState<GeoJSON>();

  const getFloorPlan = useCallback(() => {
    if (sensorGatewayDetails?.location) {
      const currentLocationFloorPlan = allFloorPlan.get(sensorGatewayDetails.location);
      if (currentLocationFloorPlan) {
        setFloorPlan(currentLocationFloorPlan);
      } else {
        fetchLocationFloorplan(sensorGatewayDetails.location)
          .then((floorplan) => {
            setFloorPlan(floorplan);
            dispatch(
              setLocationFloorplan({
                locationId: sensorGatewayDetails.location as string,
                floorplan,
              })
            );
          })
          .catch((err) => setAlertMsg({ success: false, msg: err.cause, alertType: 'error' }));
      }
    }
  }, [allFloorPlan, dispatch, sensorGatewayDetails.location]);

  useEffect(() => {
    getFloorPlan();
    const item: PropSensorItemCreate = {
      location: sensorGatewayDetails?.location ?? '',
      name: '',
      shortName: '',
      position: sensorGatewayDetails?.position,
      gateway: sensorGatewayDetails?.id,
      timeZone: sensorGatewayDetails?.timeZone,
      type: subSensorData.type,
      fwVersion: '1',
      hwVersion: subSensorData?.hwVersion ?? '',
    };
    setSensorPayload(item);
  }, [getFloorPlan, sensorGatewayDetails, subSensorData]);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, param: string) => {
    const { value } = e.target;
    let item;
    if (param === 'name') {
      const trimmedShortName = value.replace(/ +/g, '').trim().substring(0, 10);
      const capitalizedShortName =
        trimmedShortName.charAt(0).toUpperCase() + trimmedShortName.slice(1);
      item = { ...sensorPayload, name: value, shortName: capitalizedShortName };
    } else if (param === 'shortName') {
      const fixedLengthShortName = value.replace(/ +/g, '').trim().substring(0, 10);
      item = { ...sensorPayload, shortName: fixedLengthShortName };
    } else item = { ...sensorPayload, [param]: value };
    setSensorPayload(item as PropSensorItemCreate);
  };

  const createNewSensor = () => {
    const item = { ...sensorPayload };
    item.position = position;
    addNewSensor(sensorNewId, item as PropSensorItemCreate)
      .then((res) => {
        enableSensor(true);
        dispatch(setSensorsById([res as SensorLatest]));
        refreshSubSensor();
        creatingSensor(false);
        setAlertMsg({
          success: true,
          msg: 'Update successful. New Sensor added.',
          alertType: 'success',
        });
      })
      .catch((err) => {
        setAlertMsg({
          success: false,
          msg: err.cause,
          alertType: 'error',
        });
      });
  };

  const alertCloseHandler = () => {
    setAlertMsg({ success: true, msg: '', alertType: 'success' });
  };

  const formPositionChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ?? '';
    const values = newValue.split(',');
    let valid = false;
    if (values?.length === 2) {
      const latitude = Number.parseFloat(values[0]);
      const longitude = Number.parseFloat(values[1]);
      if (!Number.isNaN(latitude) && !Number.isNaN(longitude)) {
        valid = true;
        setPosition({
          ...position,
          lat: latitude,
          lng: longitude,
        });
      }
    }
    setPositionValid(valid);
  };

  const mapPositionChangeHandler = (posData: SensorPositionData) => {
    const newPosition = { ...position };
    newPosition.lat = posData?.lat ?? 0;
    newPosition.lng = posData?.lng ?? 0;
    newPosition.polygon = posData?.polygon ?? '';
    if (sensorPayload?.type === SubSensorType.OccSignatures) {
      newPosition.azimuth = position.azimuth ?? 0;
      newPosition.height = position.height ?? 2.4;
    }
    setPosition(newPosition);
  };

  return (
    <List>
      <Grid container>
        <CreateSensorForm
          sensorId={subSensorData.suggested_id}
          sensorPayload={sensorPayload}
          position={position}
          setPosition={setPosition}
          positionValid={positionValid}
          formPositionChangeHandler={formPositionChangeHandler}
          textFieldChange={onChange}
        />
        <Grid item md={6} sm={12} xs={12}>
          <div className={classes.dashboardTile}>
            <PositioningMap
              floorPlan={floorPlan}
              onChange={mapPositionChangeHandler}
              position={position}
              mapName="SensorCreate"
            />
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        {alertMsg?.msg && (
          <Alert
            severity={alertMsg.alertType ?? 'info'}
            onClose={alertCloseHandler}
            className={classes.alertMsg}
          >
            {alertMsg?.msg}
          </Alert>
        )}
      </Grid>
      <Button
        variant={themeProps.btnVariant.default}
        color="primary"
        style={{ marginTop: '10px' }}
        onClick={createNewSensor}
      >
        Add New Sensor
      </Button>
      <Button
        variant={themeProps.btnVariant.default}
        className={classes.cancelBtn}
        style={{ marginTop: '10px', marginLeft: '10px' }}
        onClick={() => creatingSensor(false)}
      >
        Cancel
      </Button>
    </List>
  );
}

export default CreateSensor;
