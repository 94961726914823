import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WifiIcon from '@mui/icons-material/Wifi';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router';
import { sensorTypeDetails } from '../../utils/sensorProperties';
import navigationStyles from '../../styles/navigationDrawer';
import useStyles from '../../styles/location';
import { GatewaySensorType, SensorLatest, VarName } from '../../services/api';
import { themeProps } from '../../styles/theme';
import { getDataBandParams } from '../../utils/dataBandParams';

interface ConfiguredSensorsListProps {
  allSensors: SensorLatest[];
}

function ConfiguredSensorsList({ allSensors }: ConfiguredSensorsListProps): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();
  const navigationClasses = navigationStyles();
  return (
    <Box>
      {allSensors?.map((sensor) => {
        const isGateway = sensor.type === GatewaySensorType.LfWiFiSensor;
        const Icon: typeof SvgIcon =
          !isGateway && sensor?.type ? sensorTypeDetails[sensor.type]?.icon ?? WifiIcon : WifiIcon;
        const TooltipText =
          !isGateway && sensor?.type ? sensorTypeDetails[sensor.type]?.label ?? 'Wifi' : 'Wifi';
        const enabledSubSensor = (sensor.gateway ?? '') !== '';
        let sensorStatusColor = themeProps.colors.grey;
        let sensorStatusDescription = 'Disabled';
        if (isGateway || enabledSubSensor) {
          const sensorStatusValue = sensor?.data?.find(
            (sensorData) => sensorData.varName === VarName.OnlineStatus
          )?.value;
          const sensorStatusBandParams =
            sensorStatusValue !== undefined &&
            getDataBandParams(VarName.OnlineStatus, sensorStatusValue);
          sensorStatusColor = sensorStatusBandParams
            ? sensorStatusBandParams.color
            : themeProps.colors.grey;
          sensorStatusDescription = sensorStatusBandParams
            ? sensorStatusBandParams.label
            : 'Unknown';
        }

        if (sensor.id) {
          const handleClick = () => {
            navigate(`/sensors/${sensor.id}`);
          };

          return (
            <Grid container className={classes.sensorList} key={sensor.id} onClick={handleClick}>
              <Grid item md={1} sm={2} xs={2} style={{ padding: '0' }}>
                <Icon className={classes.icon} />
              </Grid>
              <Grid item md={5} sm={4} xs={4} className={classes.sensorGridItem}>
                <Typography variant="body1">{sensor?.name}</Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={6}
                xs={6}
                sx={{ display: 'flex', alignItems: 'center', background: 'none' }}
                className={navigationClasses.navLink}
              >
                <Tooltip title={sensorStatusDescription}>
                  <FiberManualRecordIcon
                    style={{
                      marginRight: '5px',
                      color: sensorStatusColor,
                    }}
                  />
                </Tooltip>
                <Tooltip title={TooltipText}>
                  <Typography variant="body1">{sensor?.id}</Typography>
                </Tooltip>
              </Grid>
            </Grid>
          );
        }
        return null;
      })}
    </Box>
  );
}

export default ConfiguredSensorsList;
