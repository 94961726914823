import BluetoothAudioIcon from '@mui/icons-material/BluetoothAudio';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BoltIcon from '@mui/icons-material/Bolt';
import CycloneIcon from '@mui/icons-material/Cyclone';
import HvacIcon from '@mui/icons-material/Hvac';

import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import WavesIcon from '@mui/icons-material/Waves';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { VarName } from '../services/api/api';
import Co2Icon from '../styles/icons/Co2Icon';
import ParticulateMatterIcon from '../styles/icons/ParticulateMatterIcon';
import TemperatureIcon from '../styles/icons/TemperatureIcon';
import HumidityIcon from '../styles/icons/HumidityIcon';
import NoSignalIcon from '../styles/icons/NoSignalIcon';

export { VarName };

export type JsxIconType = (props: SvgIconProps) => JSX.Element;

export interface VarNameDetailsItem {
  id: VarName;
  label: string;
  metric: string | null;
  icon?: (props: SvgIconProps) => JSX.Element;
  stackable?: boolean;
  suggestedScaleValues?: [number, number];
  reportSortOrder?: number;
}

export enum WidgetType {
  Map,
  HistoricDataPlot,
  BandPie,
  SingleGauge,
  SingleValue,
  SummaryValueSum,
  SummaryValueAverage,
  Sunburst,
}

export const varNameDetails: Record<VarName, VarNameDetailsItem> = {
  unknown: {
    id: VarName.Unknown,
    label: 'Unknown',
    metric: null,
  },
  BACnetReads: {
    id: VarName.BaCnetReads,
    label: 'BACnet',
    metric: null,
    icon: DeviceHubIcon,
    stackable: true,
  },
  batteryLevelPct: {
    id: VarName.BatteryLevelPct,
    label: 'Battery',
    metric: '%',
    icon: BatteryChargingFullIcon,
    suggestedScaleValues: [0, 100],
  },
  CO2ppm: {
    id: VarName.Co2ppm,
    label: 'CO₂',
    metric: 'ppm',
    icon: Co2Icon,
    suggestedScaleValues: [400, 950],
    reportSortOrder: 100,
  },
  clientsBLE: {
    id: VarName.ClientsBle,
    label: 'Occupancy (BLE)',
    metric: null,
    icon: BluetoothAudioIcon,
    suggestedScaleValues: [0, 5],
    reportSortOrder: 10,
    stackable: true,
  },
  clientsWiFi: {
    id: VarName.ClientsWiFi,
    label: 'Occupancy (WiFi)',
    metric: null,
    icon: ConnectWithoutContactIcon,
    suggestedScaleValues: [0, 5],
    reportSortOrder: 10,
    stackable: true,
  },
  energyInkWh: {
    id: VarName.EnergyInkWh,
    label: 'Energy',
    metric: 'kWh',
    icon: BoltIcon,
    reportSortOrder: 100,
    stackable: true,
  },
  illuminanceArb: {
    id: VarName.IlluminanceArb,
    label: 'Light Level',
    metric: 'arb.',
    icon: WbIncandescentIcon,
  },
  motionEvent: {
    id: VarName.MotionEvent,
    label: 'Motion Events',
    metric: null,
    icon: SensorOccupiedIcon,
    reportSortOrder: 10,
  },
  occSignatures: {
    id: VarName.OccSignatures,
    label: 'Occupancy Signatures',
    metric: null,
    icon: PersonPinCircleIcon,
    reportSortOrder: 10,
    stackable: true,
  },
  onlineStatus: {
    id: VarName.OnlineStatus,
    label: 'Online Status',
    metric: null,
    icon: NoSignalIcon,
  },
  pressuremBar: {
    id: VarName.PressuremBar,
    label: 'Pressure',
    metric: 'hPa',
    icon: LineWeightIcon,
  },
  particulateMatter: {
    id: VarName.ParticulateMatter,
    label: 'Particulate Matter',
    metric: 'µg/m³',
    icon: ParticulateMatterIcon,
    suggestedScaleValues: [0, 20],
  },
  relativeHumidity: {
    id: VarName.RelativeHumidity,
    label: 'Humidity',
    metric: '%',
    icon: HumidityIcon,
    suggestedScaleValues: [40, 60],
  },
  temperatureC: {
    id: VarName.TemperatureC,
    label: 'Temperature',
    metric: '°C',
    icon: TemperatureIcon,
    suggestedScaleValues: [17, 25],
  },
  VOCppb: {
    id: VarName.VoCppb,
    label: 'Volatile Organic Compounds',
    metric: 'ppb',
    icon: WavesIcon,
  },
  frequencyHz: {
    id: VarName.FrequencyHz,
    label: 'Frequency',
    metric: 'Hz',
    icon: CycloneIcon,
  },
  outputLevelPct: {
    id: VarName.OutputLevelPct,
    label: 'Output Level',
    metric: '%',
    icon: HvacIcon,
  },
};
