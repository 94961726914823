import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { VarName } from '../../utils/varNames';
import DataStore from '../../services/dataStore';
import ColouredLinePlot from '../Plots/ColouredLinePlot';
import { SensorHistoryPlotItem } from '../Plots/plotCommon';
import { getMotionThreshold, getSensorsById } from '../../state/selectors';
import useStyles from '../../styles';
import { useAppSelector } from '../../state/store';

interface WrapperProps {
  varName: VarName;
  sensorId: string;
  transparentBg?: boolean;
  start?: number;
  end?: number;
}

export function ColouredLinePlotWrapper({
  varName,
  sensorId,
  transparentBg,
  start,
  end,
}: WrapperProps): JSX.Element {
  const classes = useStyles();
  const dataStore = DataStore.getInstance();
  const sensorDetails = useAppSelector(getSensorsById);
  const motionRange = useAppSelector(getMotionThreshold);
  const [sensorPlotData, setSensorPlotData] = useState<SensorHistoryPlotItem>({
    varName,
    sensorId,
    sensorName: sensorDetails.get(sensorId)?.name ?? '',
    history: {
      time: [],
      value: [],
    },
  });

  const [startTime, setStartTime] = useState<number | undefined>(undefined);
  const [endTime, setEndTime] = useState<number | undefined>(undefined);

  useEffect(() => {
    setStartTime(start);
    setEndTime(end);
  }, [start, end]);

  function handleRelayout(event: Plotly.PlotRelayoutEvent) {
    if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {
      let newStartTime = new Date(event['xaxis.range[0]']).getTime();
      let newEndTime = new Date(event['xaxis.range[1]']).getTime();
      // If dragged to the future limit to now and keep range from drag
      const now = Date.now();
      if (newEndTime > now) {
        const diff = newEndTime - now;
        newEndTime = now;
        newStartTime -= diff;
      }
      setStartTime(newStartTime);
      setEndTime(newEndTime);
    }
  }

  useEffect(() => {
    const emptyItem: SensorHistoryPlotItem = {
      varName,
      sensorId,
      sensorName: sensorDetails.get(sensorId)?.name ?? '',
      history: {
        time: [],
        value: [],
      },
    };
    dataStore
      .getHistory(sensorId, varName, startTime, endTime)
      .then((history) => {
        const dataItem: SensorHistoryPlotItem = {
          varName,
          sensorId,
          sensorName: sensorDetails.get(sensorId)?.name ?? '',
          history: {
            time: history.map(({ time }) => time),
            value: history.map(({ value }) => value),
          },
        };
        setSensorPlotData(dataItem);
      })
      .catch(() => setSensorPlotData(emptyItem));
  }, [varName, sensorId, dataStore, sensorDetails, startTime, endTime, motionRange]);

  return (
    <div>
      <ColouredLinePlot
        data={sensorPlotData}
        handleRelayout={(e) => handleRelayout(e)}
        transparentBg={transparentBg}
        xRange={startTime && endTime ? [new Date(startTime), new Date(endTime)] : undefined}
      />
      {sensorPlotData.history.time.length === 0 && (
        <Typography variant="body1" className={classes.plotAlertText}>
          No data
        </Typography>
      )}
    </div>
  );
}

ColouredLinePlotWrapper.defaultProps = {
  transparentBg: undefined,
  start: undefined,
  end: undefined,
};

export default ColouredLinePlotWrapper;
