import React from 'react';
import { useTheme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WifiIcon from '@mui/icons-material/Wifi';
import { NavLink } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { sensorTypeDetails } from '../../utils/sensorProperties';
import navigationStyles from '../../styles/navigationDrawer';
import useStyles from '../../styles/location';
import {
  BacnetGatewaySettings,
  BacnetSensorSettings,
  SensorLatest,
  GatewaySensorType,
} from '../../services/api';

interface BacnetSensorsListProps {
  filteredSensors: SensorLatest[];
  bacnetSensors: BacnetSensorSettings[];
  bacnetGatewaySensors: BacnetGatewaySettings[] | undefined;
}

function BacnetSensorsList({
  filteredSensors,
  bacnetSensors,
  bacnetGatewaySensors,
}: BacnetSensorsListProps): JSX.Element {
  const classes = useStyles();
  const navigationClasses = navigationStyles();
  const theme = useTheme();

  return (
    <Box>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Icon </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Sensor Id</TableCell>
              <TableCell>Bacnet Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSensors.map((sensor) => {
              const isGateway = sensor?.type === GatewaySensorType.LfWiFiSensor;
              const Type: typeof SvgIcon =
                !isGateway && sensor?.type
                  ? sensorTypeDetails[sensor.type]?.icon ?? WifiIcon
                  : WifiIcon;

              const sensorGateway = bacnetGatewaySensors?.find((gatewaySensor) =>
                gatewaySensor.sensors.some((item) => item.id === sensor.id)
              );

              const bacnetId = bacnetSensors.find(
                (bacnetSensor) => sensor.id === bacnetSensor.id
              )?.bacnet_id;

              const bacnetTooltip = `Bacnet config details \n Bacnet Id: ${bacnetId} \n Bacnet Gateway Id: ${
                sensorGateway?.id
              } \nBuilding Network no: ${
                sensorGateway?.building_network_no ?? '-'
              } \n VLan Network no: ${sensorGateway?.vlan_network_no}`;

              return (
                <TableRow>
                  <TableCell style={{ borderBottom: '0' }}>
                    <Type className={classes.icon} />
                  </TableCell>
                  <TableCell style={{ borderBottom: '0' }}>{sensor?.name}</TableCell>
                  <TableCell style={{ borderBottom: '0' }}>
                    <NavLink to={`/sensors/${sensor?.id}`} className={navigationClasses.navLink}>
                      <Tooltip title="Sensor info">
                        <Typography variant="inherit" style={{ color: theme.palette.text.primary }}>
                          {sensor?.id}
                        </Typography>
                      </Tooltip>
                    </NavLink>
                  </TableCell>
                  <TableCell style={{ borderBottom: '0' }}>
                    <Tooltip
                      title={
                        <div style={{ whiteSpace: 'pre-line', cursor: 'pointer' }}>
                          {bacnetTooltip}
                        </div>
                      }
                    >
                      <Typography variant="inherit">{bacnetId}</Typography>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default BacnetSensorsList;
