import React, { useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { getDataBandParams, varNameBandParams } from '../../utils/dataBandParams';
import { VarName } from '../../utils/varNames';
import { BandValues } from '../SensorArrayWidgets/base';
import { themeProps } from '../../styles/theme';
import { getDataValueNumber } from '../HelperComponents/DataValueString';
import Plot from './PlotlyCustom';
import {
  getActiveMarker,
  getLocSensorStatusData,
  getMotionThreshold,
  getThemeMode,
} from '../../state/selectors';
import {
  setActiveMarker,
  setClickedItem,
  setHighlightedItem,
  setSelectedBand,
} from '../../state/actions';
import { ThemeMode } from '../../state/types';
import { useAppDispatch, useAppSelector } from '../../state/store';
import { persistState, StorageTypes } from '../../utils/persistentState';

interface MotionRingPlotProps {
  locMarkerId?: string; // to generate small sized summary ring plot for child locations when called from map
  showHistory?: () => void;
}

export function MotionRingPlot({ locMarkerId, showHistory }: MotionRingPlotProps): JSX.Element {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const motionThreshold = useAppSelector(getMotionThreshold);
  const activeMarker = useAppSelector(getActiveMarker);
  const themeMode = useAppSelector(getThemeMode);
  const locSensorStatusData = useAppSelector(getLocSensorStatusData);

  const isDesktopView = useMediaQuery(theme.breakpoints.up('sm'));

  const showAllLabels = isDesktopView && !locMarkerId;

  const plotSize = useMemo(() => {
    let size = 200;
    if (locMarkerId) size = 60; // need small plot for loc summary
    else if (!isDesktopView) size = 80;
    return size;
  }, [isDesktopView, locMarkerId]);

  const onlineData = useMemo(() => {
    const locVarData = locSensorStatusData.onlineData.get(VarName.MotionEvent) ?? [];
    let data = locVarData;
    if (locMarkerId)
      data = locVarData.filter((item) => locMarkerId && item.location?.startsWith(locMarkerId));
    return data;
  }, [locMarkerId, locSensorStatusData.onlineData]);

  const offlineData = useMemo(() => {
    const locVarData = locSensorStatusData.offlineData.get(VarName.MotionEvent) ?? [];
    let data = locVarData;
    if (locMarkerId)
      data = locVarData.filter((item) => locMarkerId && item.location?.startsWith(locMarkerId));
    return data;
  }, [locMarkerId, locSensorStatusData.offlineData]);

  let lineColour = theme.palette.text.secondary;
  // HACK - Manually set the line colour to match the highlighted background
  if (activeMarker === VarName.MotionEvent) {
    if (themeMode === ThemeMode.dark) {
      lineColour = themeProps.colors.summaryLineDark;
    } else {
      lineColour = themeProps.colors.summaryLineLight;
    }
  }

  const bandDetails = [...(varNameBandParams[VarName.MotionEvent] ?? [])];

  const perBandSums = () => {
    const result: BandValues[] = bandDetails.map(({ color }) => ({
      total: 0,
      pct: 0,
      colour: color,
    }));

    onlineData.forEach((dataItem) => {
      const dataValue = getDataValueNumber(dataItem, motionThreshold);
      for (let i = 0; i < result.length; i++) {
        if ((dataValue ?? Infinity) <= bandDetails[i].upperBound) {
          result[i].total += 1;
          break;
        }
      }
    });
    // add offline data length to the per band sum array
    result.push({
      total: offlineData.length,
      pct: 0,
      colour: themeProps.colors.lightGrey,
    });

    return result;
  };

  // find band based on highest percentage of sensor from each band
  const motionBand = useMemo(() => {
    let band = bandDetails[0];
    // remove offline pct when calculating max pct
    const maxPct = Math.max(
      ...perBandSums()
        .slice(0, -1)
        .map((item) => item.total)
    );
    const bandPos = perBandSums()?.findIndex((obj) => obj.total === maxPct);
    if (bandPos && bandPos !== -1) band = bandDetails[bandPos];
    return band;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bandDetails]);

  // Varname band that includes offline band
  const combinedBand = useMemo(() => {
    const bands = [...(varNameBandParams[VarName.MotionEvent] ?? [])];
    bands.push({
      upperBound: NaN,
      color: themeProps.colors.lightGrey,
      text: 'Offline',
      label: 'Offline',
    });
    return bands;
  }, []);

  const plotData = [
    {
      values: perBandSums().flatMap((item) => item.total),
      labels: combinedBand.flatMap((item) => item.label),
      domain: { column: 0 },
      hole: 0.75,
      type: 'pie',
      marker: {
        colors: perBandSums().flatMap((item) => item.colour),
        line: {
          color: lineColour,
          width: 2,
        },
      },
      textinfo: 'none',
      direction: 'clockwise',
      sort: false,
    },
  ] as Plotly.Data[];

  const plotLayout = {
    title: {
      x: 0.5,
      y: 0.5,
    },
    font: { family: themeProps.fontFamily.body },
    annotations: [
      {
        font: {
          size: 14,
          color: motionBand?.color ?? theme.palette.text.primary,
        },
        showarrow: false,
        text: `${showAllLabels ? 'Total' : ''}`, // hide label for small plots called from location Id
        x: 0.5,
        y: 0.75,
      },
      {
        font: {
          size: showAllLabels ? 20 : 14,
          color: motionBand?.color ?? theme.palette.text.primary,
        },
        showarrow: false,
        // for motion event value needs to be checked from motionthreshold
        text:
          perBandSums().find(
            (item) =>
              item.total ===
              Math.max(
                ...perBandSums()
                  .slice(0, -1)
                  .map((bandSum) => bandSum.total)
              )
          )?.total ?? 'No Data',
        x: 0.5,
        y: showAllLabels ? 0.55 : 0.5,
      },
      {
        font: {
          size: 16,
          color: motionBand?.color ?? themeProps.colors.grey, // default color grey
        },
        showarrow: false,
        text: showAllLabels && onlineData.length > 0 ? motionBand?.label ?? '' : '',
        x: 0.5,
        y: 0.4,
      },
    ],
    width: plotSize,
    height: plotSize,
    margin: {
      l: locMarkerId ? 0 : 10,
      r: locMarkerId ? 0 : 10,
      b: locMarkerId ? 0 : 10,
      t: locMarkerId ? 0 : 10,
    },
    showlegend: false,
    plot_bgcolor: `${theme.palette.primary.main}00`, // transparent
    paper_bgcolor: `${theme.palette.primary.main}00`,
  } as Plotly.Layout;

  const handleMouseEvent = (event: Plotly.PlotMouseEvent, allowSensorSelection?: boolean) => {
    if (!locMarkerId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const bandPos = event.points[0].pointNumbers[0];
      if (bandPos !== combinedBand.length - 1) {
        dispatch(setSelectedBand({ varName: VarName.MotionEvent, band: combinedBand[bandPos] }));
        // set sensor from selected band
        if (allowSensorSelection) {
          const bandSensor = onlineData.find(
            (item) =>
              combinedBand[bandPos] ===
              getDataBandParams(
                VarName.MotionEvent,
                getDataValueNumber(item, motionThreshold) ?? Infinity
              )
          );
          if (bandSensor) {
            if (isDesktopView)
              dispatch(setClickedItem({ id: bandSensor.id, varName: VarName.MotionEvent }));
            if (showHistory) showHistory();
          }
        }
      } else dispatch(setSelectedBand(null));
    }
  };

  const handleClick = (event: Plotly.PlotMouseEvent) => {
    handleMouseEvent(event, true);
    dispatch(setActiveMarker(VarName.MotionEvent));
    persistState(VarName.MotionEvent, StorageTypes.ActiveMarker);
  };

  const handleUnHover = () => {
    if (!locMarkerId) {
      dispatch(setSelectedBand(null));
      dispatch(setHighlightedItem({ id: '' }));
    }
  };

  return (
    <Plot
      data={plotData}
      layout={plotLayout}
      config={{ displayModeBar: false }}
      useResizeHandler
      onClick={handleClick}
      onHover={(e) => handleMouseEvent(e)}
      onUnhover={handleUnHover}
    />
  );
}

MotionRingPlot.defaultProps = {
  locMarkerId: undefined,
  showHistory: undefined,
};

export default MotionRingPlot;
