import { SensorLatest } from '../../services/api';
import { getStartOfDay, getEndOfDay, THREE_DAYS } from '../../utils/functions';
import { getSensorDataVars } from '../../utils/sensors';
import { VarName } from '../../utils/varNames';
import { ActionTypes, ActionType } from '../actionTypes';
import { SensorDataState } from '../types';

const initialState: SensorDataState = {
  bleSensors: undefined,
  sensorsById: new Map(),
  sensorsByVarName: new Map(),
  sensorsByLocationId: new Map(),
  selectedVars: [],
  refreshTimeStamp: new Map(),
  selectedSensorIds: [],
  selectedStartDate: getStartOfDay(new Date(Date.now() - THREE_DAYS * 2)),
  selectedEndDate: getEndOfDay(new Date()),
};

export default function setSensorsState(
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: ActionTypes
): SensorDataState {
  switch (action.type) {
    case ActionType.SET_BLE_SENSORS: {
      const bleSensors = action.payload;
      return {
        ...state,
        bleSensors,
      };
    }

    case ActionType.REMOVE_SENSOR_DATA: {
      const { sensorsById, sensorsByVarName, sensorsByLocationId } = state;
      const sensorId = action.payload;
      const sensorDetail = sensorsById.get(sensorId);

      // remove sensorId from sensorsByvarname record
      const updatedSensorsByVarname = new Map(sensorsByVarName);
      const availableVarNames = getSensorDataVars(sensorDetail?.data ?? []);
      availableVarNames.forEach((varName) => {
        updatedSensorsByVarname.set(
          varName,
          updatedSensorsByVarname.get(varName) ?? [].filter((item) => item !== sensorId)
        );
      });

      // remove sensorId from sensors in location list
      const updatedSensorsByLocationId = new Map(sensorsByLocationId);
      if (sensorDetail?.location !== undefined) {
        const locSensors = sensorsByLocationId.get(sensorDetail.location) ?? [];
        updatedSensorsByLocationId.set(
          sensorDetail.location,
          locSensors?.filter((item) => item !== sensorId)
        );
      }

      sensorsById.delete(sensorId);

      return {
        ...state,
        sensorsByLocationId: updatedSensorsByLocationId,
        sensorsByVarName: updatedSensorsByVarname,
        sensorsById,
      };
    }
    case ActionType.SET_SENSORS_BY_ID: {
      const sensorsDetails = action.payload;
      const newSensorDetails = new Map(state.sensorsById);
      const newSensorByLoc = new Map(state.sensorsByLocationId);
      const newVarNameMap = new Map(state.sensorsByVarName);

      sensorsDetails.forEach((sensor) => {
        const { id, location, data } = sensor;
        const existingDetails = state.sensorsById.get(id);
        if (existingDetails) {
          const updatedData = [...(existingDetails?.data ?? [])];
          const newData = data ?? [];
          // update data item
          newData.forEach((dataItem) => {
            const index = updatedData.findIndex((el) => el.varName === dataItem.varName);
            if (index === -1) updatedData.push(dataItem);
            else updatedData[index] = dataItem;
          });

          // update sensor details
          const updatedSensor = { ...sensor, data: updatedData } as SensorLatest;
          newSensorDetails.set(id, { ...existingDetails, ...updatedSensor });
        } else newSensorDetails.set(id, sensor);

        // update sensors by varName
        data?.forEach((dataItem) => {
          const sensorsByVarName = [...(newVarNameMap.get(dataItem.varName) ?? [])];
          const sensorExists = sensorsByVarName?.find((el) => el === id);
          if (!sensorExists) {
            sensorsByVarName.push(id);
            newVarNameMap.set(dataItem.varName, sensorsByVarName);
          }
        });

        // update sensors by loc
        if (location) {
          const sensorsInLoc = [...(newSensorByLoc.get(location) ?? [])];
          const sensorExists = sensorsInLoc.find((el) => el === id);
          if (!sensorExists) {
            sensorsInLoc.push(id);
            newSensorByLoc.set(location, sensorsInLoc);
          }
        }
      });

      return {
        ...state,
        sensorsById: newSensorDetails,
        sensorsByVarName: newVarNameMap,
        sensorsByLocationId: newSensorByLoc,
      };
    }
    case ActionType.SET_SENSOR_VARS: {
      const selectedVars = action.payload;
      if (selectedVars.length === 0) {
        // If nothing is set then we must be on first login without
        // anything in the URL and anything in local storage
        selectedVars.push(VarName.TemperatureC);
        selectedVars.push(VarName.Co2ppm);
        if (state.bleSensors === undefined) {
          // On desktop add occupancy (on app just T/CO2)
          selectedVars.push(VarName.ClientsWiFi);
        }
      }

      return {
        ...state,
        selectedVars,
      };
    }
    case ActionType.SET_SELECTED_SENSORS: {
      const selectedSensorIds = action.payload;
      return {
        ...state,
        selectedSensorIds,
      };
    }

    case ActionType.SET_SELECTED_START_DATE: {
      const selectedStartDate = action.payload;
      return {
        ...state,
        selectedStartDate,
      };
    }
    case ActionType.SET_SELECTED_END_DATE: {
      const selectedEndDate = action.payload;
      return {
        ...state,
        selectedEndDate,
      };
    }
    case ActionType.SET_REFRESH_TIMESTAMP: {
      const timeStamp = action.payload;
      const newTimeRecord = new Map(state.refreshTimeStamp);
      if (timeStamp) newTimeRecord.set(timeStamp.varName, timeStamp.time);
      return {
        ...state,
        refreshTimeStamp: newTimeRecord,
      };
    }
    case ActionType.CLEAR_REDUX_STORE: {
      return {
        ...state,
        sensorsById: new Map(),
        sensorsByVarName: new Map(),
        sensorsByLocationId: new Map(),
        refreshTimeStamp: new Map(),
      };
    }
    default: {
      return state;
    }
  }
}
