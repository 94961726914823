import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import chunk from 'lodash/chunk';
import Plot from '../PlotlyCustom';

import { VarName } from '../../../services/api';
import {
  getColorScaleValue,
  getWeekPlotLayout,
  getHourValues,
  getDayOfWeekValues,
  getZmaxValue,
  getSubPlotResult,
  getHighlightedItems,
  getMainPlotData,
  getMinMaxAvgPerDayData,
  getAvgPerColumnData,
  getMinMaxAvgPerColumnData,
  getCsvData,
} from './calendarViewPlotHelpers';
import {
  getSelectedHours,
  getSelectedStartDate,
  getSelectedEndDate,
} from '../../../state/selectors';
import {
  CalendarSelectionType,
  HoveredPlot,
  HeatmapHoverSelection,
  PlotType,
  ValueType,
} from '../../CalendarView/helpers';
import { TimeRange } from '../plotCommon';
import { varNameBandParams } from '../../../utils/dataBandParams';
import useStyles from '../../../styles/calendarView';
import { Stats } from '../../../utils/statistics';
import { varNameDetails } from '../../../utils/varNames';
import { useAppSelector } from '../../../state/store';

interface WeekViewPlotProps {
  combinedSensorStats: Stats;
  varName: VarName;
  valueType: ValueType | undefined;
  showGradient?: boolean;
  setHoveredPlot?: ((value: HeatmapHoverSelection | undefined) => void) | undefined;
  downloadData: boolean;
  setDownloadData: (value: boolean) => void;
  sourceLabel: string;
}

function WeekViewPlot({
  combinedSensorStats,
  varName,
  valueType,
  showGradient,
  setHoveredPlot,
  downloadData,
  setDownloadData,
  sourceLabel,
}: WeekViewPlotProps): JSX.Element | null {
  const classes = useStyles();
  const theme = useTheme();
  const selectedHours = useAppSelector(getSelectedHours);
  const startDate = useAppSelector(getSelectedStartDate);
  const endDate = useAppSelector(getSelectedEndDate);

  const isTot = combinedSensorStats.totalAdded !== undefined;
  const isUtl = combinedSensorStats.utl !== undefined;

  const mainPlotXHourOfDay = useMemo(() => getHourValues(selectedHours), [selectedHours]);
  const mainPlotYDayOfWeek = getDayOfWeekValues(startDate, endDate);

  const highlightTimes: TimeRange[] = getHighlightedItems(
    mainPlotYDayOfWeek,
    !selectedHours.selectHours || selectedHours.includeWeekends
  );

  const initialMainPlotZvalues = useMemo(() => {
    const zValues: number[] = [];
    mainPlotYDayOfWeek.forEach((date) => {
      const day = date.getDay();
      mainPlotXHourOfDay.forEach((hour) => {
        const stats = combinedSensorStats.heatMapStats?.byDayAndHour.get(day)?.get(hour);
        if (stats === undefined) {
          zValues.push(-Infinity);
        } else if (isTot) {
          zValues.push(stats.totalAdded ?? -Infinity);
        } else if (isUtl) {
          const utlPct = stats.utl === undefined ? -Infinity : stats.utl * 100;
          zValues.push(utlPct);
        } else if (valueType === ValueType.min) {
          zValues.push(stats.maxMinAvg.min);
        } else if (valueType === ValueType.max) {
          zValues.push(stats.maxMinAvg.max);
        } else {
          zValues.push(Math.round(stats.maxMinAvg.mean * 10) / 10);
        }
      });
    });
    return zValues;
  }, [mainPlotXHourOfDay, mainPlotYDayOfWeek, combinedSensorStats, valueType, isTot, isUtl]);

  const zmax = useMemo(() => {
    const maxData = isTot ? Math.max(...initialMainPlotZvalues) : combinedSensorStats.maxMinAvg.max;
    return getZmaxValue([maxData], varName);
  }, [combinedSensorStats, varName, isTot, initialMainPlotZvalues]);

  // If the data has bands this will extend to the previous band (for colour scale)
  const zmin = useMemo(() => {
    if (isTot) return 0;
    if (isUtl) return 0;
    const bands = varNameBandParams[varName];
    const bandMinValue = bands ? bands[0]?.upperBound : 0;
    return Math.min(bandMinValue, combinedSensorStats.maxMinAvg.min);
  }, [isTot, isUtl, combinedSensorStats, varName]);

  const colorscaleValue = useMemo(
    () => getColorScaleValue(varName, zmin, zmax, showGradient),
    [varName, zmin, zmax, showGradient]
  );

  // Shape the data for plot and replace null values (to trick plot to show them)
  const mainPlotZValues = useMemo(() => {
    let zValueData;
    if (mainPlotXHourOfDay) {
      const shapedData = chunk(initialMainPlotZvalues, mainPlotXHourOfDay.length);
      if (shapedData) zValueData = shapedData;
    }
    return zValueData;
  }, [initialMainPlotZvalues, mainPlotXHourOfDay]);

  // zValues for subplot that shows min/max/avg from the main plot for each day
  const minMaxAvgPerDay = useMemo(() => {
    const zValues: number[] = [];
    mainPlotYDayOfWeek.forEach((date) => {
      const day = date.getDay();
      const stats = combinedSensorStats.heatMapStats?.byDayOfWeek.get(day);
      if (stats === undefined) {
        zValues.push(-Infinity, -Infinity, -Infinity);
      } else if (isTot) {
        zValues.push(stats.totalAdded ?? -Infinity);
        zValues.push(stats.totalAdded ?? -Infinity);
        zValues.push(stats.totalAdded ?? -Infinity);
      } else if (isUtl) {
        const utlPct = stats.utl === undefined ? -Infinity : stats.utl * 100;
        zValues.push(utlPct, utlPct, utlPct);
      } else {
        zValues.push(
          Number.isNaN(stats.maxMinAvg.min) ? -Infinity : stats.maxMinAvg.min,
          Number.isNaN(stats.maxMinAvg.mean)
            ? -Infinity
            : Math.round(stats.maxMinAvg.mean * 10) / 10,
          Number.isNaN(stats.maxMinAvg.max) ? -Infinity : stats.maxMinAvg.max
        );
      }
    });
    return chunk(zValues, 3);
  }, [mainPlotYDayOfWeek, combinedSensorStats, isTot, isUtl]);

  // zValues for subplot that shows just the avg from the main plot for each hours column
  const avgPerHourColumn = useMemo(() => {
    const zValues: number[] = [];
    mainPlotXHourOfDay.forEach((hour) => {
      const stats = combinedSensorStats.heatMapStats?.byHourOfDay.get(hour);
      if (stats === undefined) {
        zValues.push(-Infinity);
      } else if (isTot) {
        zValues.push(stats.totalAdded ?? -Infinity);
      } else if (isUtl) {
        const utlPct = stats.utl === undefined ? -Infinity : stats.utl * 100;
        zValues.push(utlPct);
      } else {
        zValues.push(
          Number.isNaN(stats.maxMinAvg.mean)
            ? -Infinity
            : Math.round(stats.maxMinAvg.mean * 10) / 10
        );
      }
    });
    return [zValues];
  }, [mainPlotXHourOfDay, combinedSensorStats, isTot, isUtl]);

  // zValues for subplot that shows actual min, max and avg of all time
  const minMaxAvgPerColumn = useMemo(() => {
    const stats = combinedSensorStats;
    const zValues: number[] = [];
    if (stats === undefined) {
      zValues.push(-Infinity);
      zValues.push(-Infinity);
      zValues.push(-Infinity);
    } else if (isTot) {
      zValues.push(stats.totalAdded ?? -Infinity);
      zValues.push(stats.totalAdded ?? -Infinity);
      zValues.push(stats.totalAdded ?? -Infinity);
    } else if (isUtl) {
      const utlPct = stats.utl === undefined ? -Infinity : stats.utl * 100;
      zValues.push(utlPct);
      zValues.push(utlPct);
      zValues.push(utlPct);
    } else {
      zValues.push(
        Number.isNaN(stats.maxMinAvg.min) ? -Infinity : stats.maxMinAvg.min,
        Number.isNaN(stats.maxMinAvg.mean) ? -Infinity : Math.round(stats.maxMinAvg.mean * 10) / 10,
        Number.isNaN(stats.maxMinAvg.max) ? -Infinity : stats.maxMinAvg.max
      );
    }
    return [zValues];
  }, [combinedSensorStats, isTot, isUtl]);

  const mainPlotData = getMainPlotData(
    colorscaleValue,
    mainPlotXHourOfDay,
    mainPlotYDayOfWeek,
    mainPlotZValues,
    zmin,
    zmax,
    isTot,
    varNameDetails[varName].metric ?? ''
  );

  // plotly data for subplot that shows min/max/avg from the main plot for each day
  const minMaxAvgPerDayData = getMinMaxAvgPerDayData(
    colorscaleValue,
    minMaxAvgPerDay,
    mainPlotYDayOfWeek,
    zmin,
    zmax,
    isTot,
    varNameDetails[varName].metric
  );

  // plotly data for subplot that shows just the avg from the main plot for each hours row
  const avgPerHourColumnData = getAvgPerColumnData(
    colorscaleValue,
    avgPerHourColumn,
    mainPlotXHourOfDay,
    zmin,
    zmax,
    isTot,
    varNameDetails[varName].metric
  );

  // plotly data for subplot that shows min, max and avg of all time
  const minMaxAvgPerColumnData = getMinMaxAvgPerColumnData(
    colorscaleValue,
    minMaxAvgPerColumn,
    zmin,
    zmax,
    isTot,
    varNameDetails[varName].metric
  );

  const minMaxAvgSubPlot = [
    {
      x: [PlotType.min, PlotType.avg, PlotType.max],
      y: mainPlotYDayOfWeek,
      z: minMaxAvgPerDay,
      xRef: 'x2',
      yRef: 'y',
    },
    { x: mainPlotXHourOfDay, y: [PlotType.avg], z: avgPerHourColumn, xRef: 'x', yRef: 'y2' },
    {
      x: [PlotType.min, PlotType.avg, PlotType.max],
      y: [PlotType.avg],
      z: minMaxAvgPerColumn,
      xRef: 'x2',
      yRef: 'y2',
    },
  ];

  const totSubPlot = [
    {
      x: [PlotType.tot],
      y: mainPlotYDayOfWeek,
      z: minMaxAvgPerDay,
      xRef: 'x2',
      yRef: 'y',
    },
    { x: mainPlotXHourOfDay, y: [PlotType.tot], z: avgPerHourColumn, xRef: 'x', yRef: 'y2' },
    {
      x: [PlotType.tot],
      y: [PlotType.tot],
      z: minMaxAvgPerColumn,
      xRef: 'x2',
      yRef: 'y2',
    },
  ];

  const subPlots = isTot ? totSubPlot : minMaxAvgSubPlot;

  const layout = useMemo(() => {
    let plotlyLayout;
    if (mainPlotXHourOfDay && mainPlotYDayOfWeek && mainPlotZValues && zmin !== undefined) {
      plotlyLayout = getWeekPlotLayout(
        mainPlotXHourOfDay,
        mainPlotYDayOfWeek,
        mainPlotZValues,
        valueType,
        highlightTimes,
        theme
      );
    }
    for (let i = 0; i < subPlots.length; i++) {
      const { x, y, z, xRef, yRef } = subPlots[i];
      const subPlotResult = getSubPlotResult(x, y, z, xRef, yRef, false, valueType);
      if (subPlotResult && plotlyLayout?.annotations) {
        const updatedLayout = plotlyLayout?.annotations.concat(subPlotResult);
        plotlyLayout.annotations = updatedLayout;
      }
    }
    return plotlyLayout;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zmin, valueType, highlightTimes]);

  const handleHover = (plotDatum: HoveredPlot | undefined) => {
    if (setHoveredPlot === undefined) return;
    if (plotDatum === undefined) {
      setHoveredPlot(undefined);
      return;
    }
    const filter: HeatmapHoverSelection = { hoverValue: plotDatum.z as number };
    if (plotDatum.y !== PlotType.avg && plotDatum.y !== PlotType.tot) {
      // Is a value for a specific day of the week (i.e. not summary of all days)
      filter.dayOfWeek = dayjs(plotDatum.y).get('day');
    }
    if (plotDatum.x === PlotType.min) {
      filter.summaryType = PlotType.min;
    } else if (plotDatum.x === PlotType.avg) {
      filter.summaryType = PlotType.avg;
    } else if (plotDatum.x === PlotType.max) {
      filter.summaryType = PlotType.max;
    } else if (plotDatum.x === PlotType.tot) {
      filter.summaryType = PlotType.tot;
    } else {
      // Is a specific hour
      filter.hour = typeof plotDatum.x === 'number' ? plotDatum.x : undefined;
    }
    setHoveredPlot(filter);
  };

  const hasAllPlotData =
    mainPlotData && minMaxAvgPerDayData && avgPerHourColumnData && minMaxAvgPerColumnData;

  const handleDownload = () => {
    setDownloadData(false);
    const csvData = getCsvData(
      zmin,
      mainPlotXHourOfDay,
      mainPlotYDayOfWeek,
      mainPlotZValues,
      minMaxAvgPerDay,
      avgPerHourColumn,
      minMaxAvgPerColumn,
      CalendarSelectionType.week
    );
    if (csvData) {
      const csvFile = new Blob([csvData], { type: 'text/csv' });
      const downloadLink = document.createElement('a');
      downloadLink.download = `${sourceLabel}-${varName}-${dayjs(startDate).format('LL')}-${dayjs(
        endDate
      ).format('LL')}.csv`;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
    return null;
  };

  if (hasAllPlotData && layout) {
    return (
      <>
        {downloadData && handleDownload()}
        <Plot
          data={[mainPlotData, minMaxAvgPerDayData, avgPerHourColumnData, minMaxAvgPerColumnData]}
          layout={layout}
          className={classes.plotContainer}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onHover={(e) => handleHover({ x: e.points[0].x, y: e.points[0].y, z: e.points[0].z })}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onUnhover={() => handleHover(undefined)}
          config={{ displayModeBar: false, showAxisDragHandles: false }}
          useResizeHandler
        />
      </>
    );
  }
  return null;
}

WeekViewPlot.defaultProps = {
  showGradient: true,
  setHoveredPlot: undefined,
};

export default WeekViewPlot;
