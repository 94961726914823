import React from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

import { DashSummary } from './base';
import { getMotionThreshold } from '../../state/selectors';
import { setMotionThreshold } from '../../state/actions';
import BandSensor from './BandSensor';
import useMotionMapping from './useMotionMapping';
import { motionTimeTicks } from '../../utils/motionEvents';
import { useAppDispatch, useAppSelector } from '../../state/store';

export function MotionSlider(): JSX.Element {
  const dispatch = useAppDispatch();
  const rangeValue = useAppSelector(getMotionThreshold);
  const handleChange = (e: Event, newValue: number | number[]) => {
    const newValues = newValue as number[];
    if (newValues[0] < newValues[1]) {
      dispatch(setMotionThreshold([newValues[0], newValues[1]]));
    }
  };
  return (
    <Slider
      color="primary"
      value={rangeValue}
      onChange={handleChange}
      valueLabelDisplay="off"
      aria-labelledby="motion-threshold-slider"
      marks={motionTimeTicks}
      step={null}
      min={motionTimeTicks[0].value}
      max={motionTimeTicks[motionTimeTicks.length - 1].value}
    />
  );
}

function MotionEventSensor({ data, varName, firstItem }: DashSummary): JSX.Element {
  const motionData = useMotionMapping(data);

  return (
    <BandSensor data={motionData} varName={varName} firstItem={firstItem}>
      <Typography id="motion-threshold-slider" variant="body1">
        Motion threshold
      </Typography>
      <MotionSlider />
    </BandSensor>
  );
}

export default MotionEventSensor;
