import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import { BaseAccessPoint, GatewayConfig, WiFiConfig } from '../../services/api/api';
import { updateGatewayConfig } from '../../services/apiService';
import useStyles from '../../styles';
import RSSILookup from './RSSILookup';
import { APListHeaderItem, APSubListHeaderItem } from '../../utils/sensorProperties';
import AccessPointsIcon from './AccessPointsIcon';
import { getShortTimeStr } from '../../utils/functions';

interface AccessPointsProps {
  accessPoints: BaseAccessPoint[];
  wifiConfig: WiFiConfig;
  refreshVisibleItems: () => void;
  sensorGatewayId: string;
}

function AccessPoints({
  accessPoints,
  wifiConfig,
  refreshVisibleItems,
  sensorGatewayId,
}: AccessPointsProps): JSX.Element {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState<string>();
  const [expanded, setExpanded] = useState(true);

  const changeStatus = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    enabled: boolean
  ) => {
    e.stopPropagation();
    setErrorMsg('');
    let payload: GatewayConfig = {};
    const allowList = wifiConfig?.desired?.address_allowlist ?? [];
    if (enabled) {
      payload = {
        wifi_config: {
          address_allowlist:
            wifiConfig && wifiConfig?.desired?.address_allowlist?.filter((ap) => ap !== id),
        },
      };
    } else {
      allowList?.push(id);
      payload = {
        wifi_config: {
          address_allowlist: allowList,
        },
      };
    }
    updateGatewayConfig(sensorGatewayId, payload)
      .then(() => refreshVisibleItems())
      .catch((err) => setErrorMsg(err.cause));
  };

  return (
    <Accordion style={{ marginTop: '1rem' }} expanded={expanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setExpanded(!expanded)}>
        <Typography variant="h6" style={{ marginLeft: '10px' }}>
          Visible Access Points
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List className={classes.shadowGridHeader}>
          {APListHeaderItem.map((title) => (
            <ListItem key={title.id}>
              <Typography variant="body1" color="secondary">
                {title.label}
              </Typography>
            </ListItem>
          ))}
        </List>
        {errorMsg && (
          <Alert severity="error" className={classes.alertMsg} onClose={() => setErrorMsg('')}>
            {errorMsg}
          </Alert>
        )}
        <div style={{ minHeight: '400px' }}>
          {accessPoints.map((accessPoint) => (
            <Box key={accessPoint.address_pattern}>
              <Accordion key={accessPoint.address_pattern}>
                <AccordionSummary
                  expandIcon={
                    <IconButton
                      data-testid={`expand-${accessPoint.address_pattern}`}
                      className={classes.expandBtn}
                      size="large"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.expansionSummary}
                >
                  <div className={classes.shadowSummary}>
                    <AccessPointsIcon
                      wifiConfig={wifiConfig}
                      accessPoint={accessPoint}
                      addressPattern={accessPoint.address_pattern}
                      listening={accessPoint.listening}
                      type="AP"
                      changeStatus={changeStatus}
                      parentAP=""
                    />
                    <Typography>{accessPoint?.address_pattern ?? '-'}</Typography>
                    <div className={classes.RSSI}>
                      <Typography className={classes.hideInSmallScreen}>2.4GHz</Typography>
                      <RSSILookup RSSI={accessPoint?.rssis?.twoGHz ?? undefined} />
                      <Typography className={classes.hideInSmallScreen}>5GHz</Typography>
                      <RSSILookup RSSI={accessPoint?.rssis?.fiveGHz ?? undefined} />
                    </div>
                    <Typography>{accessPoint?.aps?.length ?? 0}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  className={classes.apSubListContainer}
                  onClick={(e) => e.stopPropagation()}
                  style={{ display: 'block' }}
                >
                  <Grid container spacing={3} style={{ width: '100%' }}>
                    {APSubListHeaderItem.map((title) => (
                      <Grid
                        item
                        sm={title?.gridSize}
                        key={title?.id}
                        className={title.hideInSmallScreen ? classes.hideInSmallScreen : ''}
                      >
                        <Typography variant="body1" color="secondary" component="span">
                          {title.label}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                  {accessPoint?.aps?.map((ssid) => (
                    <Grid
                      container
                      spacing={3}
                      className={classes.apContainer}
                      key={`${ssid.ssid}-${ssid.address}`}
                    >
                      <Grid item sm={2} xs={2}>
                        <AccessPointsIcon
                          wifiConfig={wifiConfig}
                          accessPoint={accessPoint}
                          addressPattern={ssid.address}
                          listening={ssid.listening}
                          type="ssid"
                          changeStatus={changeStatus}
                          parentAP={accessPoint.address_pattern}
                        />
                      </Grid>
                      <Grid item sm={3} xs={5}>
                        <Typography>{ssid?.address ?? '-'}</Typography>
                      </Grid>
                      <Grid item sm={3} xs={3} className={classes.AccessPointsRSSI}>
                        <Typography>{ssid?.ssid ?? '-'}</Typography>
                      </Grid>
                      <Grid item sm={2} xs={2}>
                        <Typography className={classes.apSubListChannel} align="center">
                          {ssid?.channel ?? 0}
                        </Typography>
                      </Grid>
                      <Grid item sm={2} xs="auto" className={classes.hideInSmallScreen}>
                        <Typography>{getShortTimeStr(ssid?.last_seen)}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
export default AccessPoints;
