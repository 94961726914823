import { Epic } from 'redux-observable';
// isOfType seems the only type checking that works well for action1 -> action2
import { isOfType } from 'typesafe-actions';
import { from } from 'rxjs';
import { filter, mergeMap, map, withLatestFrom, switchMap } from 'rxjs/operators';
import { fetchAllLatestVarname, fetchSensorLatest } from '../../services/apiService';
import {
  goFetchAllLatestVarName,
  goFetchSensorDetails,
  setRefreshTimeStamp,
  setSensorsById,
  toggleActivePlotVar,
} from '../actions';
import { ActionType, ActionTypes } from '../actionTypes';
import { DataTreeItems, ProjectData, SensorLatest } from '../../services/api';
import { VarName } from '../../utils/varNames';
import { isDataExpired } from '../../utils/functions';
import { RootState } from '../types';

// This Epic is used to determine which data we need to get
// It will produce goFetch actions for that data
const updateFilterDataEpic: Epic<ActionTypes, ActionTypes, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isOfType([ActionType.SET_SENSOR_VARS, ActionType.GO_FETCH_REFRESH])),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      let needToFetchVars: VarName[] = [];
      const stateVars = state.sensors.selectedVars;

      if (action.type === ActionType.SET_SENSOR_VARS) {
        let defaultVars: VarName[] = [];
        const payloadVars = action.payload;
        const hasOnlineVar = payloadVars.includes(VarName.OnlineStatus);
        // include online var when selected vars changes
        if (hasOnlineVar) defaultVars = payloadVars;
        else defaultVars = [...payloadVars, VarName.OnlineStatus];

        // filter varnames to be fetched where data is older than 5 minutes
        needToFetchVars = defaultVars.filter((varName) => {
          const varNameTimeStamp = state.sensors.refreshTimeStamp.get(varName) ?? null;
          let dataFresh = false;
          if (varNameTimeStamp !== undefined) {
            dataFresh = !isDataExpired(varNameTimeStamp, 5);
          }

          return !varNameTimeStamp || !dataFresh;
        });
      } else {
        // include online var when refresh is trigerred
        const hasOnlineVar = stateVars.includes(VarName.OnlineStatus);
        if (hasOnlineVar) needToFetchVars = stateVars;
        else needToFetchVars = [...stateVars, VarName.OnlineStatus];
      }

      const actionMap = needToFetchVars.map(
        (varName) => goFetchAllLatestVarName(varName)
        // eslint-disable-next-line function-paren-newline
      );
      return actionMap;
    })
  );

// This Epic is used to add the async API call for the goFetch action
const fetchLatestVarNameDataEpic: Epic<ActionTypes, ActionTypes, RootState> = (action$) =>
  action$.pipe(
    filter(isOfType(ActionType.GO_FETCH_ALL_LATEST_VARNAME)),
    mergeMap((action) => {
      const varName = action.payload;
      const project = [ProjectData.Location, ProjectData.Value, ProjectData.Time]; // Only fetch the attributes we need
      // adding empty data manually for errors
      return from(fetchAllLatestVarname(varName, project).catch(() => <DataTreeItems>[])).pipe(
        switchMap((response) => {
          const sensorDetails: SensorLatest[] = [];

          response.data?.forEach((dataItem) => {
            const { id, location, value, time } = dataItem;
            sensorDetails.push({
              id,
              location,
              data: [{ varName, time, value }],
            });
          });

          return [
            setSensorsById(sensorDetails),
            setRefreshTimeStamp({
              varName,
              time: parseInt((new Date().getTime() / 1000).toFixed(0), 10),
            }),
          ];
        })
      );
    })
  );

const fetchSensorLatestDetailsEpic: Epic<ActionTypes, ActionTypes, RootState> = (action$) =>
  action$.pipe(
    filter(isOfType(ActionType.GO_FETCH_SENSOR_DETAILS)),
    mergeMap((action) => {
      const sensorId = action.payload;
      // project all data, adding empty data manually for errors
      return from(fetchSensorLatest(sensorId).catch(() => <SensorLatest>{ id: '' })).pipe(
        map((response) => setSensorsById([response]))
      );
    })
  );

// Watches selected sensors to see if we need to fetch details
const selectedSensorsEpic: Epic<ActionTypes, ActionTypes, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(ActionType.SET_SELECTED_SENSORS)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const actionMap = [] as ActionTypes[];
      const sensorIds = action.payload ?? [];
      if (sensorIds.length > 0) {
        sensorIds.forEach((sensorId) => {
          // If we don't have details for this sensor go fetch them
          if (!state.sensors.sensorsById.get(sensorId)) {
            actionMap.push(goFetchSensorDetails(sensorId));
          }
        });
      }
      return actionMap;
    })
  );

const updateActivePlotVarsEpic: Epic<ActionTypes, ActionTypes, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(ActionType.SET_SENSOR_VARS)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const selectedVars = action.payload;
      const { activePlotVars } = state.uiSettings;

      const varNamesToRemove: VarName[] =
        selectedVars.length > 0
          ? activePlotVars.filter((item: VarName) => selectedVars.indexOf(item) === -1)
          : [];

      const actionMap = varNamesToRemove.map(
        (varName) => toggleActivePlotVar(varName)
        // eslint-disable-next-line function-paren-newline
      );

      return actionMap;
    })
  );

export {
  updateFilterDataEpic,
  fetchLatestVarNameDataEpic,
  fetchSensorLatestDetailsEpic,
  selectedSensorsEpic,
  updateActivePlotVarsEpic,
};
