import React, { useState } from 'react';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { NavLink as RouterLink } from 'react-router';
import PersonIcon from '@mui/icons-material/Person';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Alert from '@mui/material/Alert';
import useStyles from '../../styles/location';
import { UserListItem, NewUserDetails } from '../../services/api';
import { AlertMsg, userStatus, RemoveUserProps } from './LocationProperties';
import { deleteUser, addNewUser } from '../../services/apiService';
import { getLocationsById } from '../../state/selectors';
import RemoveDialog from '../Dialog/RemoveDialog';
import { getShortTimeStr } from '../../utils/functions';
import { useAppSelector } from '../../state/store';

dayjs.extend(relativeTime);

interface UsersListProps {
  allUsers: Array<UserListItem>;
  refreshUsers: () => void;
}

interface ResendUserProps {
  userId: string;
  sending: boolean;
}

function UsersList({ allUsers, refreshUsers }: UsersListProps): JSX.Element {
  const classes = useStyles();
  const [alertMsg, setAlertMsg] = useState<AlertMsg | undefined>();
  const [resendUserProps, setResendUserProps] = useState<ResendUserProps>();
  const [removeUserProps, setRemoveUserProps] = useState<RemoveUserProps>({
    userId: '',
    locationId: '',
    dialogVisibility: false,
  });
  const locationsById = useAppSelector(getLocationsById);

  const removeUser = () => {
    const forceDelete = true;
    if (removeUserProps.userId) {
      deleteUser(removeUserProps.userId, removeUserProps.locationId, forceDelete)
        .then(() => {
          refreshUsers();
          setAlertMsg({ success: true, msg: 'User has been Removed', alertType: 'success' });
          setRemoveUserProps({
            userId: '',
            locationId: '',
            dialogVisibility: false,
          });
        })
        .catch((err) => setAlertMsg({ success: false, msg: err.cause, alertType: 'error' }));
    }
  };

  const resendInvitation = (user: UserListItem) => {
    const userProps = {
      access_level: user.access_level,
      email: user.email,
    };
    setResendUserProps({ userId: user.user_id, sending: true });
    addNewUser(user.location, userProps as NewUserDetails)
      .then(() => {
        refreshUsers();
        setAlertMsg({ success: true, msg: 'Invitation Sent successfully', alertType: 'success' });
        setResendUserProps({ userId: '', sending: false });
      })
      .catch((err) => setAlertMsg({ success: false, msg: err.cause, alertType: 'error' }));
  };

  const showHeaderField = () => (
    <Grid container spacing={3} className={classes.usersListHeader}>
      <Grid item sm={2} xs={2}>
        <ListItemText primary="Status" />
      </Grid>
      <Grid item sm={4} xs={4} style={{ overflow: 'hidden' }}>
        <ListItemText primary="Email" />
      </Grid>
      <Grid item sm={2} xs={2}>
        <ListItemText primary="Location" />
      </Grid>
      <Grid item sm={2} xs={2}>
        <ListItemText primary="Access Level" />
      </Grid>
      <Grid item sm={2} xs={2}>
        <ListItemText primary="Actions" />
      </Grid>
    </Grid>
  );

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        {alertMsg?.msg && (
          <Alert
            severity={alertMsg.alertType ?? 'info'}
            onClose={() => setAlertMsg({ success: true, msg: '', alertType: 'success' })}
            className={classes.alertMsg}
          >
            {alertMsg?.msg}
          </Alert>
        )}
        <RemoveDialog
          showDialog={removeUserProps.dialogVisibility}
          title="user"
          removeHandler={removeUser}
          cancelHandler={() =>
            setRemoveUserProps({
              userId: '',
              locationId: '',
              dialogVisibility: false,
            })
          }
        />
        <Box className={classes.usersTable}>
          {showHeaderField()}
          <Box>
            {allUsers.map((user) => {
              const { status, inviteSent } = user;
              const Icon: typeof SvgIcon = status
                ? userStatus[status]?.icon ?? PersonIcon
                : PersonIcon;
              const statusColor = status ? userStatus[status].color : 'primary';
              const tooltipStatus = status ? userStatus[status]?.label : '';
              const inviteLastSent = inviteSent && getShortTimeStr(inviteSent);
              const daysFromInvitation = inviteSent
                ? dayjs().diff(dayjs(new Date(inviteSent * 1000)), 'days')
                : null;

              return (
                <Grid container spacing={3} key={user?.user_id} className={classes.usersListItem}>
                  <Grid item sm={2} xs={2}>
                    <Tooltip title={tooltipStatus}>
                      <Icon
                        color={statusColor}
                        className={user?.status === 'active' ? classes.successIcon : ''}
                        data-testid={`status-${tooltipStatus}`}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item sm={4} xs={4} style={{ overflow: 'hidden' }}>
                    <ListItemText
                      primary={user?.email}
                      secondary={user?.status === 'pending' ? `Sent: ${inviteLastSent}` : ''}
                      secondaryTypographyProps={
                        daysFromInvitation && daysFromInvitation > 7 ? { color: 'error' } : {}
                      }
                    />
                  </Grid>
                  <Grid item sm={2} xs={2}>
                    <Link
                      component={RouterLink}
                      to={`/location/${encodeURIComponent(user?.location ?? '#')}`}
                    >
                      <ListItemText primary={locationsById.get(user?.location)?.name ?? ''} />
                    </Link>
                  </Grid>
                  <Grid item sm={2} xs={2}>
                    <ListItemText
                      primary={user?.access_level}
                      style={{ textTransform: 'capitalize' }}
                    />
                  </Grid>
                  <Grid item sm={2} xs={2}>
                    <Tooltip title="Delete User">
                      <IconButton
                        onClick={() =>
                          setRemoveUserProps({
                            userId: user.user_id,
                            locationId: user.location,
                            dialogVisibility: true,
                          })
                        }
                        className={classes.userActionBtn}
                        data-testid={`remove-user-${user.user_id}`}
                        size="large"
                      >
                        <DeleteForeverIcon className={classes.cancelIcon} />
                      </IconButton>
                    </Tooltip>
                    {user.status === 'pending' && (
                      <Tooltip title="Resend Invitation">
                        <IconButton
                          onClick={() => resendInvitation(user)}
                          className={classes.userActionBtn}
                          data-testid={`resend-invitation-${user.user_id}`}
                          size="large"
                        >
                          {resendUserProps?.sending && user.user_id === resendUserProps?.userId ? (
                            <CircularProgress size={20} />
                          ) : (
                            <RestorePageIcon className={classes.icon} />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}

export default UsersList;
