import React from 'react';
import { useTheme } from '@mui/material';
import { NavLink } from 'react-router';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import useStyles from '../../styles/navigationDrawer';
import navList, { NavListLabel } from './helpers';
import {
  getCurrentLocation,
  getSelectedSensorIds,
  getShowNavDrawer,
  getThemeMode,
  getUserIsAdmin,
} from '../../state/selectors';
import { UserAdminTypes } from '../../services/authenticationService';
import greenLogo from '../../logo_512x512_teal.png';
import whiteLogo from '../../logo_512x512_white.png';
import { setShowNavDrawer } from '../../state/actions';
import { ThemeMode } from '../../state/types';
import { useAppDispatch, useAppSelector } from '../../state/store';

function DesktopViewDrawer(): JSX.Element {
  const theme = useTheme();
  const themeMode = useAppSelector(getThemeMode);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const isUserAdmin = useAppSelector(getUserIsAdmin);
  const sensorsCompared = useAppSelector(getSelectedSensorIds);
  const locationId = useAppSelector(getCurrentLocation);
  const showNavDrawer = useAppSelector(getShowNavDrawer);

  return (
    <Drawer
      anchor="left"
      open={showNavDrawer}
      onClose={() => dispatch(setShowNavDrawer(false))}
      variant={showNavDrawer ? 'temporary' : 'permanent'}
      className={`${classes.drawer} ${showNavDrawer ? '' : classes.drawerClose}`}
      classes={{ paper: `${classes.drawer} ${showNavDrawer ? '' : classes.drawerClose}` }}
    >
      <div
        className={classes.toolbar}
        style={{ justifyContent: showNavDrawer ? 'flex-end' : 'center' }}
      >
        <IconButton
          data-testid="close"
          onClick={() => dispatch(setShowNavDrawer(!showNavDrawer))}
          size="large"
        >
          {showNavDrawer ? (
            <ChevronLeftIcon data-testid="closeBtn" color="secondary" />
          ) : (
            <img
              src={themeMode === ThemeMode.dark ? whiteLogo : greenLogo}
              alt="brand logo"
              style={{ width: '24px', height: '24px' }}
              data-testid="brand-logo"
            />
          )}
        </IconButton>
      </div>
      <List
        onClick={() => dispatch(setShowNavDrawer(false))}
        sx={{ background: theme.palette.primary.main }}
      >
        {navList.map((navMenu) => (
          <NavLink
            key={navMenu.id}
            to={
              navMenu.label === NavListLabel.config
                ? `/location/${encodeURIComponent(locationId)}`
                : navMenu.url
            }
            className={({ isActive }) =>
              [classes.navLink, isActive ? classes.navLinkActive : null].filter(Boolean).join(' ')
            }
          >
            <ListItemButton
              key={navMenu.id}
              style={{
                display:
                  navMenu?.accessLevel === UserAdminTypes.admin && !isUserAdmin ? 'none' : '',
                padding: '10px',
              }}
            >
              <Tooltip title={navMenu.label} placement="right">
                <IconButton>
                  <Badge
                    badgeContent={sensorsCompared.length > 0 && sensorsCompared.length}
                    invisible={
                      !(navMenu.label === NavListLabel.compare && sensorsCompared.length > 0)
                    }
                    color="error"
                    classes={{ badge: classes.customBadge }}
                  >
                    <navMenu.icon color="secondary" />
                  </Badge>
                </IconButton>
              </Tooltip>
              <ListItemText primary={navMenu.label} style={{ marginLeft: '10px' }} />
            </ListItemButton>
          </NavLink>
        ))}
      </List>
    </Drawer>
  );
}

export default DesktopViewDrawer;
